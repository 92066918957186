import { API, graphqlOperation } from "aws-amplify";

import { manageUserCognitoGroup } from "./graphql/mutations";

const addRemoveUserCognitoGroup = (username, groupName, action) =>
  API.graphql(
    graphqlOperation(manageUserCognitoGroup, {
      input: { username, groupName, action },
    })
  );

export default addRemoveUserCognitoGroup;
