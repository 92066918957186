const getPropertyValue = (path, object) => {
  if (!path.includes(".")) {
    return object[path];
  }

  const keys = path.split(".");

  return keys.reduce((object, key) => object?.[key], object);
};

export default getPropertyValue;
