import { TextAreaField, SelectField } from "@aws-amplify/ui-react";

import FieldsSection from "../FieldsSection";
import SocialLinks from "../SocialLinks";
import OtherLinks from "../OtherLinks";
import { selectOptions } from "../../constants";

const CommonFields = ({ user, updateUser, handlePairedFieldsChange }) => (
  <>
    <FieldsSection
      entity="user"
      fields={{
        given_name: user.given_name,
        family_name: user.family_name,
        email: user.email,
        username: user.username,
        identity_username: user.identity_username,
      }}
      updateData={updateUser}
    />
    <FieldsSection
      heading="Profile"
      entity="user"
      fields={{
        userType: (
          <SelectField
            value={user.userType}
            onChange={(event) => updateUser("userType", event.target.value)}
            placeholder="null"
            options={selectOptions.userType}
            labelHidden
          />
        ),
        status: (
          <SelectField
            value={user.status}
            onChange={(event) => updateUser("status", event.target.value)}
            placeholder="null"
            options={selectOptions.userStatus}
            labelHidden
          />
        ),
        profileCompletion: user.profileCompletion,
        tagline: user.tagline,
        bio: (
          <TextAreaField
            value={user.bio || ""}
            onChange={(event) => updateUser("bio", event.target.value)}
            resize="vertical"
            labelHidden={true}
          />
        ),
      }}
      updateData={updateUser}
    />
    <SocialLinks
      links={user.socialLinks}
      userType={user.userType}
      updateData={updateUser}
    />
    <OtherLinks links={user.otherLinks} updateData={updateUser} />
    <FieldsSection
      heading="Location"
      entity="user"
      fields={{
        "location.cityName": user.location?.cityName,
        "location.countryCode": user.location?.countryCode,
        "location.countryId": user.location?.countryId,
        "location.countryName": user.location?.countryName,
        "location.latitude": user.location?.latitude,
        "location.locationId": user.location?.locationId,
        "location.longitude": user.location?.longitude,
        "location.stateCode": user.location?.stateCode,
        "location.stateId": user.location?.stateId,
        "location.stateName": user.location?.stateName,
        "location.wikiDataId": user.location?.wikiDataId,
      }}
      updateData={handlePairedFieldsChange}
      pairedFieldsKey="location"
    />
  </>
);

export default CommonFields;
