import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@aws-amplify/ui-react";

import JobRoleSelect from "../JobRoleSelect";
import { AlertContext } from "../../context/Alert";

const SearchFields = ({ pathParam, onSearch, placeholder, entity }) => {
  const navigate = useNavigate();
  const [mainQuery, setMainQuery] = useState(pathParam || "");
  const [chosenJobRole, setChosenJobRole] = useState("");
  const [username, setUsername] = useState("");
  const { showAlert } = useContext(AlertContext);

  const prepareQuery = (query) => {
    let preparedQuery = query.trim();

    if (query.includes("@")) {
      preparedQuery = preparedQuery.toLowerCase();
    }

    return encodeURIComponent(preparedQuery);
  };

  const handleSearch = () => {
    const booleanFields = [!!mainQuery, !!chosenJobRole, !!username];

    if (booleanFields.indexOf(true) !== booleanFields.lastIndexOf(true)) {
      showAlert("Plese only use one field to search", "error");

      return;
    }

    if (mainQuery) {
      navigate(`/${entity}/${prepareQuery(mainQuery)}`);
    } else if (onSearch) {
      onSearch({
        jobRoleId: chosenJobRole,
        username: username.trim(),
      });
    }

    return;
  };

  const handleEnterPress = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    handleSearch();
  };

  return (
    <div className="flex items-center gap-x-4">
      <div className="w-[355px]">
        <TextField
          value={mainQuery}
          onChange={(event) => setMainQuery(event.target.value)}
          onKeyUp={handleEnterPress}
          placeholder={placeholder}
          labelHidden={true}
        />
      </div>
      {onSearch && (
        <>
          /
          <JobRoleSelect
            chosenJobRole={chosenJobRole}
            setChosenJobRole={setChosenJobRole}
          />
          /
          <div className="w-[355px]">
            <TextField
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              onKeyUp={handleEnterPress}
              placeholder="Username"
              labelHidden={true}
            />
          </div>
        </>
      )}
      <Button onClick={handleSearch}>Search</Button>
    </div>
  );
};

export default SearchFields;
