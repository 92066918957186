const Field = ({ label, value }) => {
  return (
    <p>
      <span className="text-gray-500">{label}: </span>
      {value || "N/A"}
    </p>
  );
};

export default Field;
