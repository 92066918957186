import { API, graphqlOperation } from "aws-amplify";

import { createApplicationMutation } from "./graphql/mutations";

const createApplication = (jobTypeId, userId) =>
  API.graphql(
    graphqlOperation(createApplicationMutation, {
      input: { jobTypeId, userId },
    })
  );

export default createApplication;
