import FieldsSection from "../FieldsSection";

const CustomerFields = ({ user, updateUser, handlePairedFieldsChange }) => {
  return (
    <>
      <FieldsSection
        entity="user"
        fields={{
          company: user.company,
          jobRole: user.jobRole,
        }}
        updateData={updateUser}
      />
      <FieldsSection
        heading="Company details"
        entity="user"
        fields={{
          "companyDetails.billingEmail": user.companyDetails?.billingEmail,
          "companyDetails.bio": user.companyDetails?.bio,
          "companyDetails.logo": user.companyDetails?.logo,
          "companyDetails.name": user.companyDetails?.name,
          "companyDetails.tagline": user.companyDetails?.tagline,
          "companyDetails.url": user.companyDetails?.url,
        }}
        updateData={handlePairedFieldsChange}
        pairedFieldsKey="companyDetails"
      />
      <FieldsSection
        heading="Company timezone"
        entity="user"
        fields={{
          "companyDetails.timezone.label": user.companyDetails?.timezone?.label,
          "companyDetails.timezone.value": user.companyDetails?.timezone?.value,
        }}
        updateData={handlePairedFieldsChange}
        pairedFieldsKey="companyDetails"
      />
      <FieldsSection
        heading="Company address"
        entity="user"
        fields={{
          "companyDetails.address.city": user.companyDetails?.address?.city,
          "companyDetails.address.country":
            user.companyDetails?.address?.country,
          "companyDetails.address.line1": user.companyDetails?.address?.line1,
          "companyDetails.address.line2": user.companyDetails?.address?.line2,
          "companyDetails.address.postalCode":
            user.companyDetails?.address?.postalCode,
          "companyDetails.address.state": user.companyDetails?.address?.state,
        }}
        updateData={handlePairedFieldsChange}
        pairedFieldsKey="companyDetails"
      />
    </>
  );
};

export default CustomerFields;
