import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@aws-amplify/ui-react";
import transformDate from "../../helpers/transformDate";

const MatchList = ({ matches, uniqueColumn }) => {
  const mapStatusToColor = {
    MATCHED: "bg-green-200",
    REJECTEDBYCUSTOMER: "bg-red-200",
    REJECTEDBYMEMBER: "bg-red-200",
    REJECTEDBYMATCHER: "bg-red-200",
  };

  const renderUniqueColumnCell = (match) => {
    switch (uniqueColumn) {
      case "Job Opportunity ID":
        return (
          <Link
            to={`/job-opportunities/${match.jobOpportunityId}`}
            className="hover:underline"
          >
            {match.jobOpportunity?.title}
          </Link>
        );
      default:
        const username = match.user?.username || match.user?.identity_username;
        return (
          username && (
            <Link to={`/users/${username}`} className="hover:underline">
              {username}
            </Link>
          )
        );
    }
  };

  return matches.length ? (
    <Table highlightOnHover={true} variation="striped">
      <TableHead>
        <TableRow>
          {["Created At", "Creator", "Status", uniqueColumn].map((title) => (
            <TableCell key={title} as="th">
              <span className="whitespace-nowrap">{title}</span>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {matches.map((match) => (
          <TableRow key={`${match.jobOpportunityId}${match.applicationId}`}>
            <TableCell>
              <Link
                to={`/matches/match?applicationId=${encodeURIComponent(
                  match.applicationId
                )}&jobOpportunityId=${match.jobOpportunityId}`}
                className="hover:underline"
              >
                {transformDate(match.createdAt)}
              </Link>
            </TableCell>
            <TableCell>{match.creator}</TableCell>
            <TableCell>
              <span className={mapStatusToColor[match.status] || ""}>
                {match.status}
              </span>
            </TableCell>
            <TableCell>{renderUniqueColumnCell(match)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <p className="font-semibold highlighted-error">No Matches</p>
  );
};

export default MatchList;
