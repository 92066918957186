import { Fragment } from "react";
import { VscEdit } from "react-icons/vsc";

import FieldsSection from "../../FieldsSection";
import { Button, SelectField } from "@aws-amplify/ui-react";
import { selectOptions } from "../../../constants";
import Field from "../molecules/Field";
import Experience from "../molecules/Experience";

const EducationItem = ({
  item,
  updateData,
  editId,
  handleEditClick,
  handleDeleteClick,
  saveUser,
  setNew,
}) => {
  const {
    id,
    degreeType,
    degreeName,
    description,
    endDate,
    fieldOfStudy,
    importSource,
    location,
    school,
    startDate,
  } = item;

  return (
    <Fragment key={id}>
      <div className="relative my-2 p-4 border rounded">
        <div className="absolute top-4 right-4 flex gap-x-2">
          {handleEditClick && (
            <button onClick={() => handleEditClick(id)}>
              <VscEdit />
            </button>
          )}
          {handleDeleteClick && (
            <button onClick={() => handleDeleteClick(id)}>x</button>
          )}
        </div>
        {editId !== id ? (
          <div className="flex gap-x-4 justify-between">
            <div>
              <h2>
                School: <span className="font-bold">{school}</span>
              </h2>
              <Field label={"Start Date"} value={startDate} />
              <Field label={"end Date"} value={endDate} />
              <Field label={"Degree Type"} value={degreeType} />
              <Field label={"Degree Name"} value={degreeName} />
              <Field label={"Import Source"} value={importSource} />
              <Field label={"Description"} value={description} />
              <Field label={"Field Of Study"} value={fieldOfStudy} />

              <div>
                <p className="text-lg font-bold">Location: </p>
              </div>
              <div className="flex flex-wrap gap-2 max-w-lg">
                <Field label={"cityName"} value={location?.cityName} />
                <Field label={"countryCode"} value={location?.countryCode} />
                <Field label={"countryId"} value={location?.countryId} />
                <Field label={"countryName"} value={location?.countryName} />
                <Field label={"latitude"} value={location?.latitude} />
                <Field label={"longitude"} value={location?.longitude} />
                <Field label={"stateCode"} value={location?.stateCode} />
                <Field label={"stateId"} value={location?.stateId} />
                <Field label={"stateName"} value={location?.stateName} />
                <Field label={"wikiDataId"} value={location?.wikiDataId} />
              </div>
            </div>
          </div>
        ) : (
          <>
            {saveUser && (
              <div className="flex justify-end gap-x-2">
                <Button variation="primary" onClick={saveUser}>
                  Save New
                </Button>
                <Button onClick={() => setNew(null)}>close</Button>
              </div>
            )}

            <FieldsSection
              entity="user"
              heading={`Education ${school || ""}`}
              fields={{
                school,
                degreeType: (
                  <SelectField
                    value={degreeType}
                    onChange={(event) =>
                      updateData(`degreeType`, event.target.value)
                    }
                    placeholder="null"
                    options={selectOptions.educationDegreeTypes}
                    labelHidden
                  />
                ),
                degreeName,
                description,
                endDate,
                fieldOfStudy,
                importSource,
                startDate,
              }}
              updateData={updateData}
            />
            <FieldsSection
              notDashed
              className="mt-2"
              entity="user"
              heading="Location"
              fields={{
                [`location.cityName`]: location?.cityName,
                [`location.countryCode`]: location?.countryCode,
                [`location.countryId`]: location?.countryId,
                [`location.countryName`]: location?.countryName,
                [`location.latitude`]: location?.latitude,
                [`location.locationId`]: location?.locationId,
                [`location.longitude`]: location?.longitude,
                [`location.stateCode`]: location?.stateCode,
                [`location.stateId`]: location?.stateId,
                [`location.stateName`]: location?.stateName,
                [`location.wikiDataId`]: location?.wikiDataId,
              }}
              updateData={updateData}
            />
          </>
        )}
      </div>
    </Fragment>
  );
};

const Education = ({ educationRecords, updateData, saveUser }) => {
  return educationRecords?.length > 0 ? (
    <Experience
      title={"Education"}
      keyName={"educationRecords"}
      items={educationRecords}
      updateData={updateData}
      saveUser={saveUser}
      Item={EducationItem}
    />
  ) : (
    <p className="my-4 font-bold">No Education</p>
  );
};

export default Education;
