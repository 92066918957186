import { useState } from "react";
import { TextField, SelectField, Button } from "@aws-amplify/ui-react";

import { selectOptions } from "../../constants";
import Tip from "../Tip";

const mapNetworkNameToDomain = {
  INSTAGRAM: "https://www.instagram.com/",
  LINKEDIN: "https://www.linkedin.com/in/",
  FACEBOOK: "https://www.facebook.com/",
  TWITTER: "https://www.twitter.com/",
  GITHUB: "https://www.github.com/",
  STACKOVERFLOW: "https://www.stackoverflow.com/users/",
  HASHNODE: "https://www.hashnode.com/@",
};

const initialNewLink = { type: "", value: "" };

const SocialLinks = ({ links, userType, updateData }) => {
  const [newLink, setNewLink] = useState(initialNewLink);
  const [error, setError] = useState(null);

  const handleLinkTypeChange = (event) => {
    setError(null);
    setNewLink((current) => ({ ...current, type: event.target.value }));
  };

  const handleLinkValueChange = (event) => {
    setNewLink((current) => ({ ...current, value: event.target.value }));
  };

  const handleAdd = () => {
    if (!newLink.type) {
      setError("Choose type");

      return;
    }

    const url = `${mapNetworkNameToDomain[newLink.type] || ""}${newLink.value}`;

    if (links?.some(({ type }) => type === newLink.type)) {
      updateData(
        "socialLinks",
        links.map((link) => {
          if (link.type === newLink.type) {
            return { type: link.type, value: url };
          }

          return link;
        })
      );
    } else {
      updateData("socialLinks", [
        ...(links || []),
        { type: newLink.type, value: url },
      ]);
    }

    setNewLink(initialNewLink);
  };

  const handleEnterPress = (event) => {
    if (event.keyCode !== 13 || !newLink.value.length) {
      return;
    }

    handleAdd();
  };

  const removeLink = (type) => {
    updateData(
      "socialLinks",
      links.filter((link) => link.type !== type)
    );
  };

  const options = [
    ...selectOptions.commonSocialLinks,
    ...(userType === "FREELANCER" || userType === "TORC_RECRUITER"
      ? selectOptions.freelanderSocialLinks
      : []),
    ...(userType === "CUSTOMER" ? selectOptions.customerSocialLinks : []),
  ];

  return (
    <section className="mt-10">
      <h2 className="font-bold">Social links</h2>
      <div className="dashed-section">
        {links?.length
          ? links.map(({ type, value }) => (
              <div key={type} className="flex flex-nowrap items-center my-4">
                <p className="basis-1/3 shrink-0">{type}</p>
                <a
                  href={value}
                  className="grow break-all"
                  rel="noreferrer"
                  target="_blank"
                >
                  {value}
                </a>
                <button className="ml-2" onClick={() => removeLink(type)}>
                  &#x2717;
                </button>
              </div>
            ))
          : "No social links"}
        <div className="flex items-start gap-x-4 mt-6">
          <SelectField
            value={newLink.type}
            onChange={handleLinkTypeChange}
            placeholder="null"
            options={options}
            labelHidden
            errorMessage={error}
            hasError={!!error}
          />
          <div className="grow">
            <TextField
              value={newLink.value}
              onChange={handleLinkValueChange}
              placeholder="Add new link or replace existing"
              labelHidden
              onKeyUp={handleEnterPress}
              outerStartComponent={
                <Button as="div">
                  <Tip>
                    For your social pages please enter the profile name instead
                    of the full URL.
                    <br />
                    e.g. <u>anderson</u> instead of{" "}
                    <u>https://www.linkedin.com/in/anderson</u>
                    <br />
                    <span className="text-xs">
                      For dev, portfolio, calendar and badgr provide full URL.
                    </span>
                  </Tip>
                </Button>
              }
            />
          </div>
          <Button
            onClick={handleAdd}
            isDisabled={!newLink.value || !newLink.type}
            style={{ whiteSpace: "nowrap" }}
          >
            Add or replace
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SocialLinks;
