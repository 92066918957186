import { API, graphqlOperation } from "aws-amplify";

import { listJobOpportunityNotesQuery } from "./graphql/queries";

const listJobOpportunityNotes = async (jobOpportunityId) => {
  const response = await API.graphql(
    graphqlOperation(listJobOpportunityNotesQuery, { jobOpportunityId })
  );

  response.data.listNotes.items = response.data.listNotes.items.filter(
    (note) => !note.applicationId
  );

  return response;
};

export default listJobOpportunityNotes;
