import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import getMatch from "../../api/getMatch";
import mutateMatch from "../../api/mutateMatch";
import useEditing from "../../hooks/useEditing";
import ActionButtons from "../ActionButtons";
import Loader from "../Loader";
import MatchFields from "../MatchFields";

const MatchEdit = () => {
  const [searchParams] = useSearchParams();
  const jobOpportunityId = searchParams.get("jobOpportunityId");
  const applicationId = searchParams.get("applicationId");
  const getMatchArgument = useMemo(
    () =>
      jobOpportunityId && applicationId
        ? { jobOpportunityId, applicationId }
        : undefined,
    [jobOpportunityId, applicationId]
  );

  const {
    isLoading,
    isNotFound,
    data: match,
    updateData: updateMatch,
    handlePairedFieldsChange,
    handleReset,
    handleSubmit,
  } = useEditing(getMatchArgument, getMatch, mutateMatch, "match");

  const isLoaded = !!Object.keys(match).length;

  return (
    <main className="main">
      {isLoading && <Loader />}
      <div className="flex justify-end items-center gap-x-8 mb-4">
        {isLoaded && (
          <ActionButtons handleReset={handleReset} handleSave={handleSubmit} />
        )}
      </div>
      {isNotFound && (
        <p className="mt-10 font-semibold highlighted-error">
          No Match is found
        </p>
      )}
      {isLoaded && (
        <MatchFields
          isLoading={isLoading}
          match={match}
          updateMatch={updateMatch}
          handlePairedFieldsChange={handlePairedFieldsChange}
        />
      )}
    </main>
  );
};

export default MatchEdit;
