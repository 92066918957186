import { API, graphqlOperation } from "aws-amplify";

import { getCognitoGroupsForUser } from "./graphql/queries";

const getCognitoGroupsForUsername = (username) =>
  API.graphql(
    graphqlOperation(getCognitoGroupsForUser, {
      username,
    })
  );

export default getCognitoGroupsForUsername;
