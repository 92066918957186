import { Link } from "react-router-dom";
import { Card, Placeholder, CheckboxField } from "@aws-amplify/ui-react";

import FieldsSection from "../FieldsSection";
import Metadata from "../Metadata";

const ApplicationFields = ({
  isLoading,
  application,
  updateApplication,
  handlePairedFieldsChange,
}) => (
  <Card variation="elevated">
    <div className="form">
      <section className="basic-info">
        <h2 className="w-fit mt-4 px-2 text-xl font-bold bg-blue-100">
          Application for Job Role:
        </h2>
        <h2 className="w-fit mt-4 mb-8 px-2 text-xl font-bold bg-blue-100">
          {application.jobType?.title}
        </h2>
        {[
          "id",
          "creator",
          "createdAt",
          "updater",
          "updatedAt",
          "systemModTimestamp",
          "systemProcessName",
        ].map((field) => (
          <div className="mt-2" key={field}>
            <p className="font-semibold">{field}</p>
            <div className="h-6">
              {isLoading ? (
                <Placeholder className="mt-2" />
              ) : (
                String(application[field])
              )}
            </div>
          </div>
        ))}
      </section>
      <div className="w-full">
        <FieldsSection
          className="mt-2"
          entity="application"
          fields={{
            availableStartDate: application.availableStartDate,
            creator: application.creator,
            updater: application.updater,
            userId: application.userId,
            isNotActive: (
              <CheckboxField
                checked={application.isNotActive || false}
                onChange={(event) =>
                  updateApplication("isNotActive", event.target.checked)
                }
              />
            ),
          }}
          updateData={updateApplication}
        />
        <FieldsSection
          heading="References"
          entity="application"
          fields={{
            "user.username": (
              <Link
                to={`/users/${
                  application.user?.username ||
                  application.user?.identity_username
                }`}
              >
                <span className="border-b-2 border-blue-200">
                  {application.user?.username ||
                    application.user?.identity_username}
                </span>
              </Link>
            ),
            jobRole: (
              <Link to={`/job-roles/${application.jobTypeId}`}>
                <span className="border-b-2 border-blue-200">
                  {application.jobType?.title}
                </span>
              </Link>
            ),
          }}
          updateData={updateApplication}
        />
        <FieldsSection
          heading="Preferred Rate"
          entity="application"
          fields={{
            "preferredRate.currency": application.preferredRate?.currency,
            "preferredRate.value": application.preferredRate?.value,
          }}
          updateData={handlePairedFieldsChange}
          pairedFieldsKey="preferredRate"
        />
        <Metadata
          metadata={application.metadata}
          updateData={updateApplication}
          newKeyOptions={
            JSON.parse(process.env.REACT_APP_PREDEFINED_METADATA_KEYS ?? "{}")
              .Application
          }
        />
      </div>
    </div>
  </Card>
);

export default ApplicationFields;
