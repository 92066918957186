import { useState, useEffect, useContext, Fragment } from "react";
import { Loader } from "@aws-amplify/ui-react";
import { VscEdit, VscArrowUp } from "react-icons/vsc";

import { AlertContext } from "../../context/Alert";
import AssessmentEditor from "../AssessmentEditor";
import getUserAssessments from "../../api/getUserAssessments";
import transformDate from "../../helpers/transformDate";

const Assessments = ({ userId, userEmail }) => {
  const [assessments, setAssessments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editedAssessmentId, setEditedAssessmentId] = useState(null);
  const [isRevealed, setIsRevealed] = useState(false);
  const { showGraphQLError } = useContext(AlertContext);

  const loadAssessments = async () => {
    try {
      setIsLoading(true);

      const response = await getUserAssessments(userId);

      setAssessments(response.data.getUserAssessments.assessments);
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditButtonClick = (id) =>
    setEditedAssessmentId((currentValue) => (currentValue !== id ? id : null));

  const renderAssessments = () => (
    <>
      {assessments?.length ? (
        <>
          {assessments
            .slice(0, isRevealed ? undefined : 2)
            .map((assessment) => {
              const {
                id,
                codeScore,
                assessmentCompleted,
                finalScore,
                multipleChoiceScore,
                plagiarism,
                reportLink,
                status,
                testId,
                testName,
                timeTaken,
              } = assessment;

              return (
                <Fragment key={id}>
                  <div className="relative my-2 p-4 border blue rounded">
                    <button
                      onClick={() => handleEditButtonClick(id)}
                      className="absolute top-4 right-4"
                    >
                      <VscEdit />
                    </button>
                    <div className="flex gap-x-4 justify-between">
                      <div>
                        <h2 className="font-bold">{testName}</h2>
                        <h3 className="text-sm text-gray-500">{testId}</h3>
                        <p className="mt-2">
                          <span className="text-gray-500">Status: </span>
                          {status}
                        </p>
                        <p>
                          <span className="text-gray-500">Plagiarism: </span>
                          {plagiarism}
                        </p>
                        <p>
                          <span className="text-gray-500">Report link: </span>
                          <a
                            href={reportLink}
                            className="break-all"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {reportLink}
                          </a>
                        </p>
                        <p>
                          <span className="text-gray-500">
                            Completion date:{" "}
                          </span>
                          {transformDate(assessmentCompleted)}
                        </p>
                      </div>
                      <div className="shrink-0 w-40">
                        <ul>
                          <span className="font-bold">Scores</span>
                          <li>{codeScore} - code</li>
                          <li>{multipleChoiceScore} - multiple choice</li>
                          <li>{finalScore} - final</li>
                          <hr className="my-1 border-black" />
                          <li>Taken time: {timeTaken}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {id === editedAssessmentId && (
                    <AssessmentEditor
                      isEditMode
                      existingAssessment={assessment}
                      setIsLoading={setIsLoading}
                      loadAssessments={loadAssessments}
                      userEmail={userEmail}
                      finishEditing={() => setEditedAssessmentId(null)}
                    />
                  )}
                </Fragment>
              );
            })}
          {assessments?.length > 2 && (
            <button
              className="w-full h-10 py-1 text-xl text-center tracking-widest border blue rounded hover:border-blue-900"
              onClick={() => setIsRevealed((currentValue) => !currentValue)}
            >
              {isRevealed ? <VscArrowUp className="mx-auto" /> : "..."}
            </button>
          )}
        </>
      ) : (
        <p>No assessments</p>
      )}
    </>
  );

  return (
    <section className="mt-10">
      <h2 className="font-bold">Assessments</h2>
      <div className="mt-4">{isLoading ? <Loader /> : renderAssessments()}</div>
      <AssessmentEditor
        userEmail={userEmail}
        loadAssessments={loadAssessments}
        setIsLoading={setIsLoading}
      />
    </section>
  );
};

export default Assessments;
