import { Button } from "@aws-amplify/ui-react";
import { useLocation } from "react-router-dom";

import BackButton from "../BackButton";

const listPages = ["job-opportunities", "job-roles"];

const mapPathToTitle = {
  "job-opportunities": "Job Opportunity list",
  "job-roles": "Job Role list",
};

const Header = ({ signOut }) => {
  const { pathname } = useLocation();
  const params = pathname.split("/").filter((el) => !!el);
  const isBackButton = listPages.includes(params[0]) && params.length === 2;
  const isTitle = listPages.includes(params[0]) && params.length === 1;
  const justifyContent =
    isBackButton || isTitle ? "justify-between" : "justify-end";

  return (
    <header
      className={`header flex items-center p-4 shadow-sm ${justifyContent}`}
    >
      {isBackButton && <BackButton path={`/${params[0]}`} />}
      {isTitle && (
        <p className="font-bold text-lg">{mapPathToTitle[params[0]]}</p>
      )}
      <Button size="small" onClick={signOut}>
        Sign out
      </Button>
    </header>
  );
};

export default Header;
