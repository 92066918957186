import { Link } from "react-router-dom";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";

import ApplicationCreate from "../ApplicationCreate";
import transformDate from "../../helpers/transformDate";

const ApplicationList = ({ applications, userId }) => (
  <>
    {applications.length ? (
      <div className="form-tab-table">
        <Table highlightOnHover={true} variation="striped">
          <TableHead>
            <TableRow>
              {["Created At", "Job Role", "Inactive", "Preferred Rate"].map(
                (title) => (
                  <TableCell key={title} as="th">
                    <span className="whitespace-nowrap">{title}</span>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map(
              ({ id, createdAt, isNotActive, jobType, preferredRate }) => (
                <TableRow key={id}>
                  <TableCell>
                    <Link
                      to={`/applications/${id}`}
                      className="hover:underline"
                    >
                      {transformDate(createdAt, true)}
                    </Link>
                  </TableCell>
                  <TableCell>{jobType?.title}</TableCell>
                  <TableCell>
                    {/* If is not active is true, means the user is not interested */}
                    {!isNotActive ? "-" : "true"}
                  </TableCell>
                  <TableCell>
                    {preferredRate?.value} {preferredRate?.currency}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </div>
    ) : (
      <p className="mt-10 font-semibold highlighted-error">No applications</p>
    )}
    {userId && (
      <ApplicationCreate applications={applications} userId={userId} />
    )}
  </>
);

export default ApplicationList;
