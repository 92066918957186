import { API, graphqlOperation } from "aws-amplify";

import { updateApplicationMutation } from "./graphql/mutations";

const mutateApplication = (id, updatePayload) =>
  API.graphql(
    graphqlOperation(updateApplicationMutation, {
      input: {
        id,
        ...updatePayload,
      },
    })
  );

export default mutateApplication;
