import { useState } from "react";
import { TextField, SelectField } from "@aws-amplify/ui-react";

import { selectOptions } from "../../constants";

const initialNewLanguage = { language: "", level: "" };

const KnownLanguages = ({ languages, updateData }) => {
  const [newLanguage, setNewLanguage] = useState(initialNewLanguage);
  const [error, setError] = useState(null);

  const handleLevelChange = (event) => {
    setError(null);
    setNewLanguage((current) => ({ ...current, level: event.target.value }));
  };

  const handleLanguageChange = (event) => {
    setNewLanguage((current) => ({ ...current, language: event.target.value }));
  };

  const handleEnterPress = (event) => {
    if (event.keyCode !== 13 || !newLanguage.language.length) {
      return;
    }

    if (!newLanguage.level) {
      setError("Choose level");

      return;
    } else {
      setError(null);
    }

    if (languages?.some(({ language }) => language === newLanguage.language)) {
      updateData("knownLanguages", [
        ...languages.filter(
          ({ language }) => language !== newLanguage.language
        ),
        newLanguage,
      ]);
    } else {
      updateData(
        "knownLanguages",
        languages ? [...languages, newLanguage] : [newLanguage]
      );
    }

    setNewLanguage(initialNewLanguage);
  };

  const handleDelete = (deletedLanguage) => {
    updateData(
      "knownLanguages",
      languages.filter(({ language }) => language !== deletedLanguage)
    );
  };

  return (
    <section className="mt-10">
      <h2 className="font-bold">Known languages</h2>
      <div className="grid grid-cols-2 gap-2 mt-4">
        {languages?.length
          ? languages.map(({ language, level }) => (
              <div
                key={language}
                className={`relative p-2 pr-6 border rounded-xl blue`}
              >
                <p className="break-all">
                  {language} - {level}
                </p>
                <button
                  type="button"
                  className="absolute top-2 right-2"
                  onClick={() => handleDelete(language)}
                >
                  &#x2717;
                </button>
              </div>
            ))
          : "No languages"}
      </div>
      <div className="flex gap-x-4 mt-4">
        <SelectField
          value={newLanguage.level}
          onChange={handleLevelChange}
          placeholder="null"
          options={selectOptions.languageLevel}
          labelHidden
          errorMessage={error}
          hasError={!!error}
        />
        <div className="grow">
          <TextField
            value={newLanguage.language}
            onChange={handleLanguageChange}
            placeholder="Add new language"
            labelHidden
            onKeyUp={handleEnterPress}
          />
        </div>
      </div>
    </section>
  );
};

export default KnownLanguages;
