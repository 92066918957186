import getPlatformSiteDomain from "../../helpers/getPlatformSiteDomain";

const PlatformRedirectButton = ({ entity, path }) => (
  <a
    href={`${getPlatformSiteDomain()}${path}`}
    target="_blank"
    rel="noreferrer"
    className="block w-max mt-4 px-2 text-black font-semibold bg-blue-100"
  >
    View {entity}
  </a>
);

export default PlatformRedirectButton;
