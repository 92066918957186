import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

import SimpleHeader from "../SimpleHeader";
import Header from "../Header";
import Navigation from "../Navigation";
import Loader from "../Loader";
import "./index.css";
import ErrorBoundary from "../ErrorBoundary";

const PrivateRoute = ({ children, user, signOut }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    (async () => {
      const auth = await Auth.currentSession();
      const groups = auth.getAccessToken().payload["cognito:groups"] || [];

      if (groups.includes(process.env.REACT_APP_COGNITO_ADMIN_GROUP)) {
        setIsAllowed(true);
        setIsLoading(false);

        return;
      }

      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAllowed) {
    return (
      <>
        <SimpleHeader signOut={signOut} />
        <div className="my-10">
          <p className="mx-auto mb-3 font-bold highlighted-error">
            You are not authorized to use this service.
          </p>
          <p className="mx-auto mb-10 highlighted-error">
            Please contact administrator if you think this is a mistake.
          </p>
        </div>
      </>
    );
  }

  return (
    <div className="page-grid">
      <Header signOut={signOut} />
      <Navigation user={user} />
      <ErrorBoundary key={location.pathname}>
        <Outlet />
      </ErrorBoundary>
      {children}
    </div>
  );
};

export default PrivateRoute;
