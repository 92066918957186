import { useParams } from "react-router-dom";

import getJobOpportunity from "../../api/getJobOpportunity";
import mutateJobOpportunity from "../../api/mutateJobOpportunity";
import useEditing from "../../hooks/useEditing";
import SearchFields from "../SearchFields";
import ActionButtons from "../ActionButtons";
import Loader from "../Loader";
import JobOpportunityFields from "../JobOpportunityFields";

const JobOpportunityEdit = () => {
  const { jobOpportunityId } = useParams();

  const {
    isLoading,
    isNotFound,
    data: jobOpportunity,
    updateData: updateJobOpportunity,
    handlePairedFieldsChange,
    handleReset,
    handleSubmit,
  } = useEditing(
    jobOpportunityId,
    getJobOpportunity,
    mutateJobOpportunity,
    "jobOpportunity"
  );

  const isLoaded = !!Object.keys(jobOpportunity).length;

  return (
    <main className="main">
      {isLoading && <Loader />}
      <div className="flex justify-between items-center gap-x-8 mb-4">
        <SearchFields
          pathParam={jobOpportunityId}
          placeholder="Job Opportunity ID"
          entity="job-opportunities"
        />
        {isLoaded && (
          <ActionButtons handleReset={handleReset} handleSave={handleSubmit} />
        )}
      </div>
      {isNotFound && (
        <p className="mt-10 font-semibold highlighted-error">
          No Job Opportunity is found
        </p>
      )}
      {isLoaded && (
        <JobOpportunityFields
          isLoading={isLoading}
          jobOpportunity={jobOpportunity}
          updateJobOpportunity={updateJobOpportunity}
          handlePairedFieldsChange={handlePairedFieldsChange}
        />
      )}
    </main>
  );
};

export default JobOpportunityEdit;
