import { Fragment } from "react";
import { VscEdit } from "react-icons/vsc";

import FieldsSection from "../../FieldsSection";
import { Button, SelectField } from "@aws-amplify/ui-react";
import { selectOptions } from "../../../constants";
import Field from "../molecules/Field";
import Experience from "../molecules/Experience";

const CareerItem = ({
  item,
  updateData,
  editId,
  handleEditClick,
  handleDeleteClick,
  saveUser,
  setNew,
}) => {
  const {
    id,
    companyName,
    description,
    endDate,
    format,
    locationType,
    stack,
    startDate,
    title,
  } = item;

  return (
    <Fragment key={id}>
      <div className="relative my-2 p-4 border rounded">
        <div className="absolute top-4 right-4 flex gap-x-2">
          {handleEditClick && (
            <button onClick={() => handleEditClick(id)}>
              <VscEdit />
            </button>
          )}
          {handleDeleteClick && (
            <button onClick={() => handleDeleteClick(id)}>x</button>
          )}
        </div>
        {editId !== id ? (
          <div className="flex gap-x-4 justify-between">
            <div>
              <h2>
                Company: <span className="font-bold">{companyName}</span>
              </h2>
              <Field label={"Title"} value={title} />
              <Field label={"Start Date"} value={startDate} />
              <Field label={"end Date"} value={endDate || "Present"} />
              <Field label={"Format"} value={format} />
              <Field label={"Location Type"} value={locationType} />
              <Field label={"Stack"} value={stack} />
              <Field label={"Description"} value={description} />
            </div>
          </div>
        ) : (
          <>
            {saveUser && (
              <div className="flex justify-end gap-x-2">
                <Button variation="primary" onClick={saveUser}>
                  Save New
                </Button>
                <Button onClick={() => setNew(null)}>close</Button>
              </div>
            )}

            <FieldsSection
              entity="user"
              heading={`Company: ${companyName || ""}`}
              fields={{
                companyName,
                title,
                format: (
                  <SelectField
                    value={format}
                    onChange={(event) =>
                      updateData("format", event.target.value)
                    }
                    placeholder="null"
                    options={selectOptions.careerFormatTypes}
                    labelHidden
                  />
                ),
                locationType: (
                  <SelectField
                    value={locationType}
                    onChange={(event) =>
                      updateData("locationType", event.target.value)
                    }
                    placeholder="null"
                    options={selectOptions.careerLocationTypes}
                    labelHidden
                  />
                ),
                stack,
                description,
                endDate,
                startDate,
              }}
              updateData={updateData}
            />
          </>
        )}
      </div>
    </Fragment>
  );
};

const Careers = ({ careers, updateData, saveUser }) => {
  return careers?.length > 0 ? (
    <Experience
      title={"Careers"}
      keyName={"careers"}
      items={careers}
      updateData={updateData}
      saveUser={saveUser}
      Item={CareerItem}
    />
  ) : (
    <p className="my-4 font-bold">No Careers</p>
  );
};

export default Careers;
