import { API, graphqlOperation } from "aws-amplify";

import { updateJobOpportunityMutation } from "./graphql/mutations";

const mutateJobOpportunity = (id, updatePayload) =>
  API.graphql(
    graphqlOperation(updateJobOpportunityMutation, {
      input: {
        id,
        ...updatePayload,
      },
    })
  );

export default mutateJobOpportunity;
