import { useState } from "react";
import { Loader } from "@aws-amplify/ui-react";

import BadgeAdding from "../BadgeAdding";
import transformDate from "../../helpers/transformDate";

const Badges = ({ badges, userId, refreshBadges, isLoading }) => {
  const [newBadge, setNewBadge] = useState(null);

  const handleDelete = () => {
    setNewBadge(null);
  };

  const oldAndNewBadges = newBadge ? [...badges, newBadge] : badges;

  const renderBadges = () =>
    oldAndNewBadges.length
      ? oldAndNewBadges.map((badge) => (
          <div
            key={badge.entityId}
            className={`relative flex items-start gap-x-3 p-2 border rounded-xl green`}
          >
            {!badge.issuedOn && (
              <button
                type="button"
                className="absolute top-1 right-2"
                onClick={() => handleDelete(badge.entityId)}
              >
                &#x2717;
              </button>
            )}
            <div className="flex-shrink-0">
              <img src={badge.image} alt={badge.name} className="w-16" />
            </div>
            <div>
              <div className="flex gap-x-2 items-start">
                <h2 className="font-bold">{badge.name}</h2>
                <div className="w-max mt-0.5 mr-4 px-2 text-sm rounded-full bg-cyan-500 text-white">
                  {badge.count}
                </div>
              </div>
              <p className="font-medium text-gray-400 text-sm">
                {badge.description}
              </p>
              {badge.issuedOn && (
                <p className="text-sm text-gray-400">
                  {transformDate(badge.issuedOn)}
                </p>
              )}
            </div>
          </div>
        ))
      : "No badges";

  return (
    <section className="mt-10">
      <h2 className="font-bold">Badges</h2>
      <div className="grid grid-cols-2 gap-2 mt-4 mb-2">
        {isLoading ? <Loader /> : renderBadges()}
      </div>
      <BadgeAdding
        badges={oldAndNewBadges}
        newBadge={newBadge}
        setNewBadge={setNewBadge}
        userId={userId}
        refreshBadges={refreshBadges}
      />
    </section>
  );
};

export default Badges;
