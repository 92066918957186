const transformDate = (date, withTime = false) => {
  if (!date) return "-";

  if (withTime) {
    return new Date(date).toLocaleTimeString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return new Date(date).toLocaleDateString("en-US");
};

export default transformDate;
