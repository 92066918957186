const deletePropertyValue = (path, object, onlyIfEmpty) => {
  if (
    !path ||
    (!path.includes(".") && onlyIfEmpty && Object.keys(object[path]).length)
  ) {
    return;
  }

  if (!path.includes(".") && !onlyIfEmpty) {
    delete object[path];

    return;
  }

  const keys = path.split(".");
  const propertyToChange = keys[keys.length - 1];

  keys.pop();

  const lastObject = keys.reduce((object, key) => object[key], object);

  if (onlyIfEmpty && !Object.keys(lastObject).length) {
    return;
  }

  delete lastObject[propertyToChange];

  deletePropertyValue(keys.join("."), object, true);
};

export default deletePropertyValue;
