import { Fragment } from "react";
import { VscEdit } from "react-icons/vsc";

import FieldsSection from "../../FieldsSection";
import { Button, SelectField } from "@aws-amplify/ui-react";
import { selectOptions } from "../../../constants";
import Field from "../molecules/Field";
import Experience from "../molecules/Experience";

const ProjectCaseStudyItem = ({
  item,
  updateData,
  editId,
  handleEditClick,
  handleDeleteClick,
  saveUser,
  setNew,
}) => {
  const {
    id,
    client,
    description,
    endDate,
    workType,
    link,
    stack,
    startDate,
    title,
  } = item;

  return (
    <Fragment key={id}>
      <div className="relative my-2 p-4 border rounded">
        <div className="absolute top-4 right-4 flex gap-x-2">
          {handleEditClick && (
            <button onClick={() => handleEditClick(id)}>
              <VscEdit />
            </button>
          )}
          {handleDeleteClick && (
            <button onClick={() => handleDeleteClick(id)}>x</button>
          )}
        </div>
        {editId !== id ? (
          <div className="flex gap-x-4 justify-between">
            <div>
              <h2>
                Clien: <span className="font-bold">{client}</span>
              </h2>
              <Field label={"Title"} value={title} />
              <Field label={"Start Date"} value={startDate} />
              <Field label={"end Date"} value={endDate || "Present"} />
              <Field label={"Work Type"} value={workType} />
              <Field label={"Stack"} value={stack} />
              <Field label={"Link"} value={link} />
              <Field label={"Description"} value={description} />
            </div>
          </div>
        ) : (
          <>
            {saveUser && (
              <div className="flex justify-end gap-x-2">
                <Button variation="primary" onClick={saveUser}>
                  Save New
                </Button>
                <Button onClick={() => setNew(null)}>close</Button>
              </div>
            )}

            <FieldsSection
              entity="user"
              heading={`${workType === "PROJECT" ? "Project" : "Case Study"}`}
              fields={{
                client,
                title,
                startDate,
                endDate,
                workType: (
                  <SelectField
                    value={workType}
                    onChange={(event) =>
                      updateData("workType", event.target.value)
                    }
                    placeholder="null"
                    options={selectOptions.projectsCaseStudiesWorkTypes}
                    labelHidden
                  />
                ),
                stack,
                link,
                description,
              }}
              updateData={updateData}
            />
          </>
        )}
      </div>
    </Fragment>
  );
};

const ProjectsCaseStudies = ({ projectsCaseStudies, updateData, saveUser }) => {
  return projectsCaseStudies?.length > 0 ? (
    <Experience
      title={"Projects & Case Studies"}
      keyName={"projectsCaseStudies"}
      items={projectsCaseStudies}
      updateData={updateData}
      saveUser={saveUser}
      Item={ProjectCaseStudyItem}
    />
  ) : (
    <p className="my-4 font-bold">No Projects CaseStudies</p>
  );
};

export default ProjectsCaseStudies;
