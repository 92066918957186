import { API, graphqlOperation } from "aws-amplify";

import { deleteApplicationMutation } from "./graphql/mutations";

const deleteApplication = (id) =>
  API.graphql(
    graphqlOperation(deleteApplicationMutation, {
      input: { id },
    })
  );

export default deleteApplication;
