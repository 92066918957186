import { Button } from "@aws-amplify/ui-react";

const ActionButtons = ({ handleReset, handleSave }) => {
  return (
    <div className="flex gap-x-4">
      <Button onClick={handleReset}>Reset</Button>
      <Button variation="primary" onClick={handleSave}>
        Save
      </Button>
    </div>
  );
};

export default ActionButtons;
