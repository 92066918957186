import { NavLink } from "react-router-dom";
import { VscAccount } from "react-icons/vsc";
import { isProduction } from "../../helpers/getPlatformSiteDomain";

import logo from "../../assets/logo.png";

const JOB_RELATED_PATHS = [
  "/users",
  "/applications",
  "/job-opportunities",
  "/job-roles",
  "/matches",
];

const Navigation = ({ user }) => {
  const links = [
    { path: "/applications", label: "Application" },
    // { path: "/groups", label: "Cognito Groups" },
    { path: "/job-opportunities", label: "Job Opportunity" },
    { path: "/job-roles", label: "Job Role" },
    // { path: "/matches", label: "Match" },
    { path: "/users", label: "User" },
  ];

  return (
    <nav className="sidebar font-semibold bg-blue-50 border-double border-r-2">
      <div className="sticky top-0 flex flex-col justify-between h-[100vh]">
        <div className="flex flex-col">
          <div className="p-4 pb-[11px] border-double border-b-2">
            <img className="h-10" src={logo} alt="Torc" />
          </div>
          {links.map(({ path, label }) => (
            <NavLink
              key={path}
              className={({ isActive }) => `
                px-4 py-2 border-l-4 border-b-2 bottom-double
                ${isActive ? "border-l-red-500" : "border-l-transparent"}
                ${
                  JOB_RELATED_PATHS.includes(path)
                    ? "text-black"
                    : "text-gray-500"
                }
                hover:border-l-blue-400
              `}
              to={path}
            >
              {label}
            </NavLink>
          ))}
        </div>
        <div className="text-red-600 text-lg px-5">
          {isProduction() ? "" : "SANDBOX"}
        </div>
        <p className="flex items-center gap-x-2 p-4 border-double border-t-2">
          <VscAccount />
          {user.username}
        </p>
      </div>
    </nav>
  );
};

export default Navigation;
