import { API, graphqlOperation } from "aws-amplify";

import { updateJobRoleMutation } from "./graphql/mutations";

const mutateJobRole = (id, updatePayload) =>
  API.graphql(
    graphqlOperation(updateJobRoleMutation, {
      input: {
        id,
        ...updatePayload,
      },
    })
  );

export default mutateJobRole;
