import useJobOpportunitySearch from "../../hooks/useJobOpportunitySearch";
import SearchFields from "../SearchFields";
import Loader from "../Loader";
import JobOpportunityList from "../JobOpportunityList";

const JobOpportunitySearch = () => {
  const { onSearch, jobOpportunityList, isListLoading } =
    useJobOpportunitySearch();

  return (
    <main className="main">
      {isListLoading && <Loader />}
      <div className="mb-4">
        <SearchFields
          onSearch={onSearch}
          placeholder="Job Opportunity ID"
          entity="job-opportunities"
        />
      </div>
      {jobOpportunityList && (
        <JobOpportunityList jobOpportunities={jobOpportunityList} />
      )}
    </main>
  );
};

export default JobOpportunitySearch;
