import { useState, useMemo } from "react";
import { TextField, Loader } from "@aws-amplify/ui-react";
import { debounce } from "lodash-es";

import searchSkills from "../../api/searchSkills";

const Skills = ({
  title = "Skills",
  updateFieldKey = "skills",
  skills,
  updateSkills,
}) => {
  const [query, setQuery] = useState("");
  const [foundSkills, setFoundSkills] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDelete = (id) => {
    updateSkills(
      updateFieldKey,
      skills.filter((skill) => skill.id !== id)
    );
  };

  const handleAdd = (newSkill) => {
    if (skills.some((skill) => skill.id === newSkill.id)) {
      return;
    }

    setQuery("");
    setFoundSkills(null);

    updateSkills(updateFieldKey, [...skills, newSkill]);
  };

  const fetchSkills = useMemo(
    () =>
      debounce(async (term) => {
        try {
          setIsLoading(true);

          const response = await searchSkills(term);

          setFoundSkills(response.data.searchSkills);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }, 300),
    []
  );

  const handleQueryChange = (event) => {
    setQuery(event.target.value);

    if (!event.target.value) {
      setFoundSkills(null);

      return;
    }

    fetchSkills(event.target.value);
  };

  return (
    <section className="mt-10">
      <h2 className="font-bold">{title}</h2>
      <div className="grid grid-cols-2 gap-2 mt-4 mb-2">
        {skills.length
          ? skills.map((skill) => (
              <div
                key={skill.id}
                className={`relative p-2 pr-6 border rounded-xl red`}
              >
                <button
                  type="button"
                  className="absolute top-2 right-2"
                  onClick={() => handleDelete(skill.id)}
                >
                  &#x2717;
                </button>
                <p>{skill.name}</p>
              </div>
            ))
          : "No skills"}
      </div>
      <div className="relative">
        <TextField
          placeholder="Find new"
          value={query}
          onChange={handleQueryChange}
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)}
          innerEndComponent={
            <div className="mt-3 mr-3">{isLoading && <Loader />}</div>
          }
        />
        {isDropdownOpen && foundSkills && (
          <div className="absolute top-full w-full mt-1 p-4 rounded-sm bg-white shadow flex flex-wrap gap-1 z-20">
            {foundSkills.length ? (
              foundSkills.map((skill) => (
                <span
                  key={skill.id}
                  onClick={() => handleAdd(skill)}
                  className="px-3 py-1 font-semibold text-sm bg-gray-100 rounded-full cursor-pointer"
                >
                  {skill.name}
                </span>
              ))
            ) : (
              <p>No results</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Skills;
