import { useParams } from "react-router-dom";

import getApplication from "../../api/getApplication";
import mutateApplication from "../../api/mutateApplication";
import useEditing from "../../hooks/useEditing";
import SearchFields from "../SearchFields";
import ActionButtons from "../ActionButtons";
import Loader from "../Loader";
import ApplicationFields from "../ApplicationFields";

const ApplicationEdit = () => {
  const { applicationId } = useParams();

  const {
    isLoading,
    isNotFound,
    data: application,
    updateData: updateApplication,
    handlePairedFieldsChange,
    handleReset,
    handleSubmit,
  } = useEditing(
    applicationId,
    getApplication,
    mutateApplication,
    "application"
  );

  const isLoaded = !!Object.keys(application).length;

  return (
    <main className="main">
      {isLoading && <Loader />}
      <div className="flex justify-between items-center mb-4">
        <SearchFields
          pathParam={applicationId}
          placeholder="Application ID"
          entity="applications"
        />
        {isLoaded && (
          <ActionButtons handleReset={handleReset} handleSave={handleSubmit} />
        )}
      </div>
      {isNotFound && (
        <p className="mt-10 font-semibold highlighted-error">
          No Application is found
        </p>
      )}
      {isLoaded && (
        <ApplicationFields
          isLoading={isLoading}
          application={application}
          updateApplication={updateApplication}
          handlePairedFieldsChange={handlePairedFieldsChange}
        />
      )}
    </main>
  );
};

export default ApplicationEdit;
