import { useState, useEffect, useContext } from "react";
import { SelectField } from "@aws-amplify/ui-react";

import listJobRolesForSelect from "../../api/listJobRolesForSelect";
import { AlertContext } from "../../context/Alert";

const JobRoleSelect = ({
  chosenJobRole,
  setChosenJobRole,
  filterCallback = () => true,
}) => {
  const [jobRoles, setJobRoles] = useState([]);
  const { showGraphQLError } = useContext(AlertContext);

  useEffect(() => {
    (async () => {
      try {
        const response = await listJobRolesForSelect();

        setJobRoles(response.data.listJobTypes.items);
      } catch (error) {
        showGraphQLError(error.errors);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectField
      value={chosenJobRole}
      onChange={(event) => setChosenJobRole(event.target.value)}
      placeholder="Select Job Role"
      labelHidden
    >
      {jobRoles.filter(filterCallback).map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </SelectField>
  );
};

export default JobRoleSelect;
