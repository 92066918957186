import { useState, useEffect, useContext } from "react";

import { AlertContext } from "../context/Alert";
import listJobOpportunities from "../api/listJobOpportunities";
import getJobRoleQuery from "../api/getJobRole";
import getUserByUsername from "../api/getUserByUsername";

const useJobOpportunitySearch = () => {
  const [jobOpportunityList, setJobOpportunityList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { showGraphQLError, showAlert } = useContext(AlertContext);

  const loadLatestJobOpportunities = async () => {
    try {
      setIsLoading(true);

      const response = await listJobOpportunities();

      setJobOpportunityList(response.data.listJobOpportunitys.items);
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadLatestJobOpportunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadJobOpportunitiesByJobRole = async (jobRoleId) => {
    try {
      setIsLoading(true);

      const response = await getJobRoleQuery(jobRoleId);

      setJobOpportunityList(response.data.fields.jobOpportunities.items);
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const loadJobOpportunitiesByUsername = async (username) => {
    try {
      setIsLoading(true);

      const response = await getUserByUsername(username);

      if (response.data.fields.items.length === 0) {
        throw Error;
      }

      setJobOpportunityList(
        response.data.fields.items[0].jobOpportunities.items
      );
    } catch (error) {
      showAlert("Wrong username", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = ({ jobRoleId, username }) => {
    if (jobRoleId) {
      loadJobOpportunitiesByJobRole(jobRoleId);
    } else if (username) {
      loadJobOpportunitiesByUsername(username);
    }

    return;
  };

  return {
    jobOpportunityList,
    isListLoading: isLoading,
    onSearch,
  };
};

export default useJobOpportunitySearch;
