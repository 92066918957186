import { API, graphqlOperation } from "aws-amplify";

import { updateUserMutation } from "./graphql/mutations";

const mutateUser = (id, updatePayload) =>
  API.graphql(
    graphqlOperation(updateUserMutation, {
      input: {
        id,
        ...updatePayload,
      },
    })
  );

export default mutateUser;
