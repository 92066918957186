/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kwo7itle4jbzfk42v2pkbmqcsm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-g7ziz6ytfzeahbwfibk3tkmfti",
    "aws_cognito_identity_pool_id": "us-east-1:7aab7517-cadc-4370-b203-05c86e1d00a5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5ShYZs2fq",
    "aws_user_pools_web_client_id": "5a067g0cttgjict4q8ssf6bcf6",
    "oauth": {
        "domain": "torc-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://admin-staging.opentorc.com/,https://admin-staging.torc.dev/,https://platform-staging.opentorc.com/,https://platform-staging.torc.dev/,https://search-staging.opentorc.com/,https://search-staging.torc.dev/,https://torc-staging.auth.us-east-1.amazoncognito.com",
        "redirectSignOut": "http://localhost:3000/,https://admin-staging.opentorc.com/,https://admin-staging.torc.dev/,https://platform-staging.opentorc.com/,https://platform-staging.torc.dev/,https://search-staging.opentorc.com/,https://search-staging.torc.dev/,https://torc-staging.auth.us-east-1.amazoncognito.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "GithubToken-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "Badge-staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "torcidentitydbaf2ff0ce664e0ba096a94868fde872143505-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
