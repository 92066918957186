import { useState, createContext, useMemo, useCallback } from "react";
import { Alert } from "@aws-amplify/ui-react";

const AlertContext = createContext({});

const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const [variation, setVariation] = useState("info");
  const [timerId, setTimerId] = useState(null);

  const showAlert = useCallback(
    (message, variation = "info") => {
      if (timerId) {
        clearTimeout(timerId);
      }

      setAlert(message);
      setVariation(variation);

      const newTimerId = setTimeout(
        () => {
          setAlert(null);
          setTimerId(null);
        },
        variation === "error" ? 10000 : 5000
      );

      setTimerId(newTimerId);
    },
    [timerId]
  );

  const showGraphQLError = useCallback(
    (errors) => {
      if (!errors?.length) {
        return;
      }

      showAlert(errors.map(({ message }) => message).join("\n"), "error");
    },
    [showAlert]
  );

  const value = useMemo(
    () => ({
      showGraphQLError,
      showAlert,
    }),
    [showAlert, showGraphQLError]
  );

  return (
    <AlertContext.Provider value={value}>
      {children}
      {alert && (
        <div className="fixed bottom-4 left-4 right-4 w-fit">
          <Alert variation={variation}>{alert}</Alert>
        </div>
      )}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
