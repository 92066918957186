import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Tabs, TabItem, Placeholder } from "@aws-amplify/ui-react";
import { orderBy } from "lodash-es";

import listUserNotes from "../../api/listUserNotes";
import CommonFields from "../CommonFields/index";
import FreelancerFields from "../FreelancerFields";
import CustomerFields from "../CustomerFields";
import SystemFields from "../SystemFields";
import ImageViewer from "../ImageViewer";
import ApplicationList from "../ApplicationList";
import JobOpportunityList from "../JobOpportunityList";
import MatchList from "../MatchList";
import useBadges from "../../hooks/useBadges";
import PlatformRedirectButton from "../PlatformRedirectButton";
import NoteList from "../NoteList";

const UserFields = ({
  isLoading,
  user,
  updateUser,
  handlePairedFieldsChange,
  saveUser,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      !["0", "1", "2", "3", "4", "5", "6", "7"].includes(
        searchParams.get("tab")
      )
    ) {
      setSearchParams({ tab: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const badgesObject = useBadges(user.username || user.identity_username);

  const combinedMatches = orderBy(
    [
      ...user.matches.items,
      ...user.jobOpportunities.items.reduce(
        (acc, jobOpportunity) => [...acc, ...jobOpportunity.matches.items],
        []
      ),
    ],
    "createdAt",
    "desc"
  );

  return (
    <Card variation="elevated">
      <div className="form">
        <section className="basic-info">
          <div className="flex justify-center items-center w-60 h-60 mb-8 rounded-full shadow-md">
            <ImageViewer
              objectKey={user.headshotKey}
              radius={9999}
              placeholder={user.username}
            />
          </div>
          {[
            "id",
            "username",
            "creator",
            "createdAt",
            "updater",
            "updatedAt",
            "systemModTimestamp",
            "systemProcessName",
          ].map((field) => (
            <div className="mt-2" key={field}>
              <p className="font-semibold">{field}</p>
              <div className="h-6">
                {isLoading ? (
                  <Placeholder className="mt-2" />
                ) : (
                  String(user[field])
                )}
              </div>
            </div>
          ))}
          <PlatformRedirectButton
            entity="Profile"
            path={`#/profile/${user.username || user.identity_username}`}
          />
        </section>
        <div className="grow overflow-x-auto">
          <Tabs
            currentIndex={+searchParams.get("tab")}
            onChange={(i) => setSearchParams({ tab: i })}
            spacing="equal"
          >
            <TabItem title="Common">
              <CommonFields
                user={user}
                updateUser={updateUser}
                handlePairedFieldsChange={handlePairedFieldsChange}
              />
            </TabItem>
            {(user.userType === "FREELANCER" ||
              user.userType === "TORC_RECRUITER") && (
              <TabItem title="Freelancer">
                <FreelancerFields
                  user={user}
                  updateUser={updateUser}
                  handlePairedFieldsChange={handlePairedFieldsChange}
                  badgesObject={badgesObject}
                  saveUser={saveUser}
                />
              </TabItem>
            )}
            {user.userType === "CUSTOMER" && (
              <TabItem title="Customer">
                <CustomerFields
                  user={user}
                  updateUser={updateUser}
                  handlePairedFieldsChange={handlePairedFieldsChange}
                />
              </TabItem>
            )}
            <TabItem title="System">
              <SystemFields user={user} updateUser={updateUser} />
            </TabItem>
            <TabItem title="Notes">
              <NoteList
                loadNotes={() => listUserNotes(user.id)}
                createNotePayload={{ userId: user.id }}
              />
            </TabItem>
            {(user.userType === "CUSTOMER" ||
              user.userType === "TORC_RECRUITER") && (
              <TabItem title="Job&nbsp;Opportunities">
                <div className="form-tab-table">
                  <JobOpportunityList
                    jobOpportunities={user.jobOpportunities.items}
                    isCustomerHidden
                  />
                </div>
              </TabItem>
            )}
            {(user.userType === "FREELANCER" ||
              user.userType === "TORC_RECRUITER") && (
              <TabItem title="Job Roles">
                <ApplicationList
                  applications={user.applications.items}
                  userId={user.id}
                />
              </TabItem>
            )}
            <TabItem title="Matches">
              <div className="form-tab-table">
                <MatchList
                  matches={combinedMatches}
                  uniqueColumn="Job Opportunity ID"
                />
              </div>
            </TabItem>
          </Tabs>
        </div>
      </div>
    </Card>
  );
};

export default UserFields;
