import { useState, useContext } from "react";

import Modal from "../Modal";
import Loader from "../Loader";
import deleteMatch from "../../api/deleteMatch";
import { AlertContext } from "../../context/Alert";

const MatchDelete = ({ applicationId, jobOpportunityId }) => {
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showGraphQLError } = useContext(AlertContext);

  const handleDelete = async () => {
    try {
      setIsLoading(true);

      await deleteMatch({ applicationId, jobOpportunityId });

      window.location.reload();
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <button
        type="button"
        className="w-full mt-10 p-2 text-rose-700 border-2 border-rose-700 uppercase font-semibold"
        onClick={() => setIsConfirmationOpened(true)}
      >
        Delete this Match
      </button>
      {isConfirmationOpened && (
        <Modal onClose={() => setIsConfirmationOpened(false)}>
          <h2 className="text-lg uppercase font-bold">Confirmation</h2>
          <p className="mt-4">
            All data related to this Match will be deleted.
          </p>
          <div className="w-max mt-4 ml-auto">
            <button
              type="button"
              className="p-2 text-rose-700 border-2 border-rose-700 uppercase font-semibold"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MatchDelete;
