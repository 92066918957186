import { useState, useContext } from "react";
import {
  Expander,
  ExpanderItem,
  TextField,
  CheckboxField,
  Button,
} from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";

import { AlertContext } from "../../context/Alert";
import createAssessment from "../../api/createAssessment";
import DatePicker from "../../components/DatePicker";

const initialAssessment = {
  plagiarism: "",
  reportLink: "",
  status: "",
  testId: "",
  testName: "",
  multipleChoiceScore: "",
  codeScore: "",
  finalScore: "",
  timeTaken: "",
  awardBadge: false,
  updateUserLastActivityDate: false,
};

const AssessmentEditor = ({
  isEditMode,
  existingAssessment,
  userEmail,
  loadAssessments,
  setIsLoading,
  finishEditing,
}) => {
  const [assessment, setAssessment] = useState(
    isEditMode ? existingAssessment : initialAssessment
  );
  const [assessmentDate, setAssessmentDate] = useState(
    isEditMode
      ? existingAssessment.assessmentCompleted &&
          new Date(existingAssessment.assessmentCompleted)
      : new Date()
  );
  const { showGraphQLError, showAlert } = useContext(AlertContext);

  const handleChange = (event, isCheckbox) => {
    setAssessment((currentAssessment) => ({
      ...currentAssessment,
      [event.target.name]: isCheckbox
        ? event.target.checked
        : event.target.value,
    }));
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      const payload = {
        ...Object.entries(assessment).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value !== "" ? value : null,
          }),
          {}
        ),
        assessmentCompleted: assessmentDate?.toISOString() || null,
        email: userEmail,
        id: isEditMode ? assessment.id : uuidv4(),
      };

      const response = await createAssessment(payload);

      showAlert(response.data.createAssessment.message, "success");

      if (isEditMode) {
        finishEditing();
      } else {
        setAssessment(initialAssessment);
        setAssessmentDate(new Date());
      }

      loadAssessments();
    } catch (error) {
      showGraphQLError(error.errors);
      setIsLoading(false);
    }
  };

  const accordionTitle = isEditMode ? `Edit assessment` : "Add new assessment";

  return (
    <div className="my-4 px-0.5">
      <Expander
        type="single"
        isCollapsible
        style={{ boxShadow: "none", border: "1px solid grey" }}
      >
        <ExpanderItem title={accordionTitle} value="assessment">
          <div className="flex items-end gap-x-4 [&>div]:grow [&>div]:basis-0">
            <TextField
              name="testName"
              value={assessment.testName ?? ""}
              onChange={handleChange}
              label="Test Name"
              size="small"
            />
            <div>
              <p className="font-bold text-sm">Scores</p>
              <div className="flex gap-x-2">
                <TextField
                  type="number"
                  name="multipleChoiceScore"
                  value={assessment.multipleChoiceScore ?? ""}
                  onChange={handleChange}
                  label="Multiple Ch."
                  size="small"
                />
                <TextField
                  type="number"
                  name="codeScore"
                  value={assessment.codeScore ?? ""}
                  onChange={handleChange}
                  label="Code"
                  size="small"
                />
                <TextField
                  type="number"
                  name="finalScore"
                  value={assessment.finalScore ?? ""}
                  onChange={handleChange}
                  label="Final"
                  size="small"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-x-4 mt-2 [&>div]:grow [&>div]:basis-0">
            <TextField
              name="testId"
              value={assessment.testId ?? ""}
              onChange={handleChange}
              label="Test ID"
              size="small"
            />
            <TextField
              name="status"
              value={assessment.status ?? ""}
              onChange={handleChange}
              label="Status"
              size="small"
            />
            <TextField
              name="plagiarism"
              value={assessment.plagiarism ?? ""}
              onChange={handleChange}
              label="Plagiarism"
              size="small"
            />
          </div>
          <div className="mt-2">
            <TextField
              name="reportLink"
              value={assessment.reportLink ?? ""}
              onChange={handleChange}
              label="Report link"
              size="small"
            />
          </div>
          <div className="flex gap-x-4 mt-2 [&>div]:grow [&>div]:basis-0">
            <DatePicker
              value={assessmentDate}
              onChange={setAssessmentDate}
              label="Completion Date"
              size="small"
            />
            <TextField
              type="number"
              name="timeTaken"
              value={assessment.timeTaken ?? ""}
              onChange={handleChange}
              label="Time Taken"
              size="small"
            />
          </div>
          <div className="flex items-end gap-x-4 [&>button]:ml-auto mt-4">
            {!isEditMode && (
              <div>
                <CheckboxField
                  name="awardBadge"
                  checked={assessment.awardBadge}
                  onChange={(event) => handleChange(event, true)}
                  label="Award badge"
                  size="small"
                />
                <CheckboxField
                  name="updateUserLastActivityDate"
                  checked={assessment.updateUserLastActivityDate}
                  onChange={(event) => handleChange(event, true)}
                  label="Update User Last Activity Date"
                  size="small"
                />
              </div>
            )}
            <Button type="button" onClick={handleSave} size="small">
              Save assessment
            </Button>
          </div>
        </ExpanderItem>
      </Expander>
    </div>
  );
};

export default AssessmentEditor;
