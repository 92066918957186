import { useEffect } from "react";

const Modal = ({ children, onClose }) => {
  const handleEsc = (event) => {
    if (event.keyCode !== 27) {
      return;
    }

    onClose();
  };

  useEffect(() => {
    document.addEventListener("keyup", handleEsc);

    return () => {
      document.removeEventListener("keyup", handleEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event) => {
    if (event.target.id !== "modalWrapper") {
      return;
    }

    onClose();
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-[#0000004D] overflow-y-auto"
      onClick={handleClose}
      id="modalWrapper"
    >
      <article className="w-[600px] p-6 bg-white mx-auto mt-[25vh] mb-10">
        {children}
      </article>
    </div>
  );
};

export default Modal;
