const urlMatchesLocation = (url) => {
  if (window.location.href.includes(url)) {
    return url;
  }

  return null;
};

const modifyAwsConfig = (awsConfig) => {
  awsConfig.oauth.domain =
    process.env.REACT_APP_COGNITO_HOSTED_DOMAIN || awsConfig.oauth.domain;

  const aAcceptableSignInURLs = awsConfig.oauth.redirectSignIn.split(",");
  const aAcceptableSignOutURLs = awsConfig.oauth.redirectSignOut.split(",");

  awsConfig.oauth.redirectSignIn =
    aAcceptableSignInURLs.find(urlMatchesLocation);
  awsConfig.oauth.redirectSignOut =
    aAcceptableSignOutURLs.find(urlMatchesLocation);

  // More details about cookieStorage config see link
  // https://docs.amplify.aws/javascript/prev/build-a-backend/auth/set-up-auth/#set-up-and-connect-backend-resources
  // "Set up your Auth backend resources" -> "Existing Resources"
  awsConfig.Auth = {
    ...awsConfig.Auth,
    cookieStorage: {
      domain: window.location.hostname,
      sameSite: "strict",
      secure: true,
    },
  };
};

export default modifyAwsConfig;
