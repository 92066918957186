import { Component } from "react";

import Modal from "../Modal";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, modalIsClosed: false };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error, modalIsClosed } = this.state;

    if (modalIsClosed) {
      return <h2 className="p-4 font-bold text-lg">Unhandled Error</h2>;
    }

    if (error) {
      return (
        <Modal onClose={() => this.setState({ modalIsClosed: true })}>
          <h2 className="font-bold text-lg">Unhandled Error</h2>
          <hr className="my-4" />
          <p className="font-semibold text-lg">{error.message}</p>
          <hr className="my-4" />
          <p className="font-semibold">Stack:</p>
          <p>{error.stack}</p>
        </Modal>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
