import { useState, useEffect, useContext } from "react";

import { AlertContext } from "../context/Alert";
import getUserBadges from "../api/getUserBadges";

const useBadges = (username) => {
  const [userBadges, setUserBadges] = useState(null);
  const [toggleBadges, setToggleBadges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showGraphQLError } = useContext(AlertContext);

  const fetchUserBadges = async () => {
    setIsLoading(true);

    try {
      const response = await getUserBadges(username);

      setUserBadges(response.data.getUserBadges.badges);
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserBadges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleBadges]);

  const refreshBadges = () => setToggleBadges((current) => !current);

  return {
    userBadges,
    refreshBadges,
    isLoading,
  };
};

export default useBadges;
