import { useContext } from "react";
import { VscCopy } from "react-icons/vsc";

import { AlertContext } from "../../context/Alert";

const TextToCopy = ({ text }) => {
  const { showAlert } = useContext(AlertContext);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    showAlert("ID is copied", "success");
  };

  return (
    <div className="flex gap-x-2">
      <button type="button" onClick={handleCopy}>
        <VscCopy id="copyIcon" />
      </button>
      <span className="whitespace-nowrap">{text}</span>
    </div>
  );
};

export default TextToCopy;
