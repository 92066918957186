import { API, graphqlOperation } from "aws-amplify";

import { updateMatchMutation } from "./graphql/mutations";

const mutateMatch = ({ applicationId, jobOpportunityId }, updatePayload) =>
  API.graphql(
    graphqlOperation(updateMatchMutation, {
      input: {
        applicationId,
        jobOpportunityId,
        ...updatePayload,
      },
    })
  );

export default mutateMatch;
