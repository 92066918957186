import { API, graphqlOperation } from "aws-amplify";

import { deleteUserMutation } from "./graphql/mutations";

const deleteUser = (id) =>
  API.graphql(
    graphqlOperation(deleteUserMutation, {
      input: { id },
    })
  );

export default deleteUser;
