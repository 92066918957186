import { API, graphqlOperation } from "aws-amplify";

import { createBadgeMutation } from "./graphql/mutations";

const createBadge = (badgeId, userId, notifyUser) =>
  API.graphql(
    graphqlOperation(createBadgeMutation, {
      input: {
        badgeId,
        userId,
        notifyUser,
      },
    })
  );

export default createBadge;
