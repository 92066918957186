import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";

import { AlertContext } from "../../context/Alert";
import Loader from "../Loader";
import JobRoleList from "../JobRoleList";
import listJobRoles from "../../api/listJobRoles";

const JobRoleSearch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobRoleList, setJobRoleList] = useState(null);
  const [fileDownloadUrl, setFileDownloadUrl] = useState("");
  const { showGraphQLError } = useContext(AlertContext);
  const hiddenLinkRef = useRef();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const response = await listJobRoles();

        setJobRoleList(response.data.listJobTypes.items);
      } catch (error) {
        showGraphQLError(error.errors);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fileDownloadUrl) {
      hiddenLinkRef.current.click();
      URL.revokeObjectURL(fileDownloadUrl);
      setFileDownloadUrl("");
    }
  }, [fileDownloadUrl]);

  const handleJSONDownload = () => {
    const mappedJobRoleList = jobRoleList.map(
      ({
        skills,
        requirements,
        responsibilities,
        commitment,
        lowEndLength,
        highEndLength,
        applicationCount,
        isActive,
        ...keepProperties
      }) => ({
        ...keepProperties,
        skills: skills?.map(({ name }) => name) || null,
        requirements: requirements?.split("\n") || null,
        responsibilities: responsibilities?.split("\n") || null,
        Commitment: commitment,
        LowEndLength: lowEndLength,
        HighEndLength: highEndLength,
      })
    );

    const jobRolesJSON = JSON.stringify(mappedJobRoleList, null, 2);
    const blob = new Blob([jobRolesJSON]);
    const fileDownloadUrl = URL.createObjectURL(blob);

    setFileDownloadUrl(fileDownloadUrl);
  };

  return (
    <main className="main">
      {isLoading && <Loader />}
      <div className="flex justify-end gap-x-4 ml-auto mb-4">
        <a
          style={{ display: "none" }}
          download="mockJobs.json"
          href={fileDownloadUrl}
          ref={hiddenLinkRef}
        >
          download it
        </a>
        <Button
          variation="link"
          onClick={handleJSONDownload}
          disabled={!jobRoleList?.length}
        >
          Download JSON
        </Button>
        <Button as={Link} to="/job-roles/create">
          Create new Job Role
        </Button>
      </div>
      {jobRoleList && <JobRoleList jobRoles={jobRoleList} />}
    </main>
  );
};

export default JobRoleSearch;
