import { useState } from "react";
import { VscArrowUp } from "react-icons/vsc";

import { cloneDeep, set } from "lodash-es";
import { Button } from "@aws-amplify/ui-react";

const Experience = ({ items, keyName, updateData, saveUser, Item, title }) => {
  const [editId, setEditId] = useState(null);
  const [newItem, setNew] = useState(null);
  const [isRevealed, setIsRevealed] = useState(false);

  const handleFieldChange = (path, value) => {
    const clonedItems = cloneDeep(items);

    set(clonedItems, path, value);

    updateData(keyName, clonedItems);
  };

  const handleDeleteClick = (id) => {
    updateData(
      keyName,
      items.filter((c) => c.id !== id)
    );
  };

  const handleNewItemChange = (path, value) => {
    const clonedItems = cloneDeep(newItem) || {};

    set(clonedItems, path, value);
    setNew(clonedItems);
  };

  const saveNewItem = async () => {
    if (Object.keys(newItem)?.length === 0) {
      return;
    }

    const clonedItems = cloneDeep(items);

    clonedItems.unshift(newItem);

    saveUser({ [keyName]: clonedItems });
    setNew(null);
  };

  const handleEditClick = (id) =>
    setEditId((currentValue) => (currentValue !== id ? id : null));

  return (
    <section className="mt-5">
      <div className="flex gap-x-2 items-center">
        <h2 className="font-bold">{title}</h2>
        {!newItem && <Button onClick={() => setNew({})}>Add new</Button>}
      </div>

      {newItem && (
        <Item
          item={newItem}
          setNew={setNew}
          updateData={handleNewItemChange}
          saveUser={() => {
            saveNewItem();
          }}
        />
      )}
      {items?.slice(0, isRevealed ? undefined : 2).map((item, index) => {
        return (
          <Item
            key={item.id}
            item={item}
            updateData={(key, value) => {
              const path = `[${index}].${key}`;
              handleFieldChange(path, value);
            }}
            editId={editId}
            setEditId={setEditId}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        );
      })}
      {items?.length > 2 && (
        <button
          className="w-full h-10 py-1 text-xl text-center tracking-widest border blue rounded hover:border-blue-900"
          onClick={() => setIsRevealed((currentValue) => !currentValue)}
        >
          {isRevealed ? <VscArrowUp className="mx-auto" /> : "..."}
        </button>
      )}
    </section>
  );
};

export default Experience;
