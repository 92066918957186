import React, { useContext, useState } from "react";
import classNames from "classnames";
import { TextAreaField } from "@aws-amplify/ui-react";

import { ReactComponent as OpenAIIcon } from "../../../../assets/openai-icon.svg";
import generateJobShortDescription from "../../../../api/generateJobShortDescription";
import { AlertContext } from "../../../../context/Alert";

const ShortDescription = ({ updateJobOpportunity, jobOpportunity }) => {
  const { showGraphQLError } = useContext(AlertContext);

  const [isGeneratingShortDescription, setIsGeneratingShortDescription] =
    useState(false);

  const handleGenerateShortDescriptionClick = async () => {
    setIsGeneratingShortDescription((prev) => !prev);

    try {
      const { data } = await generateJobShortDescription(
        JSON.stringify(jobOpportunity)
      );
      if (data.generateJobShortDescription?.shortDescription) {
        updateJobOpportunity(
          "shortDescription",
          data.generateJobShortDescription.shortDescription
        );
      }
    } catch (err) {
      showGraphQLError(err.errors);
      console.log(
        "Something went wrong while generating job short description",
        err
      );
    }

    setIsGeneratingShortDescription((prev) => !prev);
  };

  return (
    <div
      className={classNames("mt-px w-full relative", {
        "animate-pulse": isGeneratingShortDescription,
      })}
    >
      <button
        onClick={handleGenerateShortDescriptionClick}
        disabled={isGeneratingShortDescription}
      >
        <OpenAIIcon
          title="Click to generate"
          className={classNames("absolute top-2 -right-2 w-[40px] h-[40px]", {
            "animate-spin": isGeneratingShortDescription,
          })}
        />
      </button>
      <TextAreaField
        value={jobOpportunity.shortDescription || ""}
        onChange={(event) =>
          updateJobOpportunity("shortDescription", event.target.value)
        }
        isDisabled={isGeneratingShortDescription}
        resize="vertical"
        labelHidden={true}
        rows={6}
      />
    </div>
  );
};

export default ShortDescription;
