import { Button } from "@aws-amplify/ui-react";

import logo from "../../assets/logo.png";

const SimpleHeader = ({ signOut }) => (
  <header className="header flex items-center p-4 shadow-sm justify-between">
    <div>
      <img className="h-10" src={logo} alt="Torc" />
    </div>
    <Button size="small" onClick={signOut}>
      Sign out
    </Button>
  </header>
);

export default SimpleHeader;
