import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { SearchField } from "@aws-amplify/ui-react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const Calendar = ({ top, left, value, onChange, onClose }) => {
  useEffect(() => {
    const backdropClickHandler = (event) => {
      if (event.target.closest(".datepicker")) {
        return;
      }

      onClose();
    };

    document.addEventListener("mousedown", backdropClickHandler);

    return () =>
      document.removeEventListener("mousedown", backdropClickHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DayPicker
      mode="single"
      selected={value}
      onSelect={onChange}
      modifiersClassNames={{ selected: "bg-blue-200" }}
      className="datepicker absolute z-50 m-0 p-4 bg-white shadow-lg border-2 border-blue-200 rounded"
      style={{ top, left }}
    />
  );
};

const DatePicker = ({ value, onChange, placeholder, label, size }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    const { bottom, left } = inputRef.current.getBoundingClientRect();

    setCalendarPosition({
      left: Math.ceil(left + window.scrollX),
      top: Math.ceil(bottom + window.scrollY) + 8,
    });
  }, [isOpen]);

  return (
    <>
      <SearchField
        ref={inputRef}
        value={value ? format(value, "MM/dd/yyyy") : ""}
        onClickCapture={() => setIsOpen(true)}
        onClear={() => onChange(undefined)}
        placeholder={placeholder}
        isReadOnly
        label={label}
        labelHidden={!label}
        hasSearchButton={false}
        size={size}
      />
      {isOpen &&
        ReactDOM.createPortal(
          <Calendar
            top={calendarPosition.top}
            left={calendarPosition.left}
            value={value}
            onChange={onChange}
            onClose={() => setIsOpen(false)}
          />,
          document.getElementById("root")
        )}
    </>
  );
};

export default DatePicker;
