import React, { useEffect, useState } from "react";
import FieldsSection from "../../../FieldsSection";

const TYPES = {
  SALARY: "SALARY",
  PER_HOUR: "PER_HOUR",
};

const hasKeys = (field) => {
  return Object.keys(field || {})?.length > 0;
};

const Compensation = ({ handlePairedFieldsChange, jobOpportunity }) => {
  const [type, setType] = useState(
    hasKeys(jobOpportunity.maxSalary) || hasKeys(jobOpportunity.minSalary)
      ? TYPES.SALARY
      : TYPES.PER_HOUR
  );

  useEffect(() => {
    setType(
      hasKeys(jobOpportunity.maxSalary) || hasKeys(jobOpportunity.minSalary)
        ? TYPES.SALARY
        : TYPES.PER_HOUR
    );
  }, [jobOpportunity.maxSalary, jobOpportunity.minSalary]);

  return (
    <div className="mt-8">
      <div className="border border-gray-300 rounded-full text-gray-400 w-fit">
        <span
          className={`p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-l-full text-center font-normal inline-block border-r border-gray-300 ${
            type === TYPES.PER_HOUR ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => {
            setType(TYPES.PER_HOUR);
          }}
        >
          Hourly Rate
        </span>
        <span
          className={`p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-r-full text-center font-normal inline-block border-r border-l border-gray-300 ${
            type === TYPES.SALARY ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => setType(TYPES.SALARY)}
        >
          Yearly Salary
        </span>
      </div>

      {type === TYPES.SALARY ? (
        <FieldsSection
          heading="Salary Max Rate"
          entity="jobOpportunity"
          fields={{
            "maxSalary.currency": jobOpportunity.maxSalary?.currency,
            "maxSalary.value": jobOpportunity.maxSalary?.value,
          }}
          updateData={handlePairedFieldsChange}
          pairedFieldsKey="maxSalary"
          className="!mt-2"
        />
      ) : (
        <FieldsSection
          heading="Max Rate"
          entity="jobOpportunity"
          fields={{
            "maxRate.currency": jobOpportunity.maxRate?.currency,
            "maxRate.value": jobOpportunity.maxRate?.value,
          }}
          updateData={handlePairedFieldsChange}
          pairedFieldsKey="maxRate"
          className="!mt-2"
        />
      )}

      {type === TYPES.SALARY ? (
        <FieldsSection
          heading="Salary Min Rate"
          entity="jobOpportunity"
          fields={{
            "minSalary.currency": jobOpportunity.minSalary?.currency,
            "minSalary.value": jobOpportunity.minSalary?.value,
          }}
          updateData={handlePairedFieldsChange}
          pairedFieldsKey="minSalary"
        />
      ) : (
        <FieldsSection
          heading="Min Rate"
          entity="jobOpportunity"
          fields={{
            "minRate.currency": jobOpportunity.minRate?.currency,
            "minRate.value": jobOpportunity.minRate?.value,
          }}
          updateData={handlePairedFieldsChange}
          pairedFieldsKey="minRate"
        />
      )}
    </div>
  );
};

export default Compensation;
