import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Tabs,
  TabItem,
  Card,
  Placeholder,
  SelectField,
  TextAreaField,
} from "@aws-amplify/ui-react";

import listJobOpportunityNotes from "../../api/listJobOpportunityNotes";
import FieldsSection from "../FieldsSection";
import Skills from "../Skills";
import Metadata from "../Metadata";
import PlatformRedirectButton from "../PlatformRedirectButton";
import NoteList from "../NoteList";
import MatchList from "../MatchList";
import StringArrayField from "../StringArrayField";
import { selectOptions } from "../../constants";
import ShortDescription from "./molecules/ShortDescription";
import Compensation from "./molecules/Compensation";

const JobOpportunityFields = ({
  isLoading,
  jobOpportunity,
  updateJobOpportunity,
  handlePairedFieldsChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!["0", "1", "2"].includes(searchParams.get("tab"))) {
      setSearchParams({ tab: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card variation="elevated">
      <div className="form">
        <section className="basic-info">
          <h2 className="w-fit mt-4 px-2 text-xl font-bold bg-blue-100">
            {jobOpportunity.jobType?.title} Opportunity:
          </h2>
          <h2 className="w-fit mt-4 mb-8 px-2 text-xl font-bold bg-blue-100">
            {jobOpportunity.title}
          </h2>
          {[
            "id",
            "creator",
            "createdAt",
            "updater",
            "updatedAt",
            "systemModTimestamp",
            "systemProcessName",
          ].map((field) => (
            <div className="mt-2" key={field}>
              <p className="font-semibold">{field}</p>
              <div className="h-6">
                {isLoading ? (
                  <Placeholder className="mt-2" />
                ) : (
                  String(jobOpportunity[field])
                )}
              </div>
            </div>
          ))}
          <PlatformRedirectButton
            entity="Job Opportunity"
            path={`#/jobs/opportunities/${jobOpportunity.id}/preview`}
          />
        </section>
        <div className="grow overflow-x-auto">
          <Tabs
            currentIndex={+searchParams.get("tab")}
            onChange={(i) => setSearchParams({ tab: i })}
            spacing="equal"
          >
            <TabItem title="Common">
              <FieldsSection
                entity="jobOpportunity"
                fields={{
                  title: jobOpportunity.title,
                  priorityLevel: (
                    <SelectField
                      value={jobOpportunity.priorityLevel}
                      onChange={(event) =>
                        updateJobOpportunity(
                          "priorityLevel",
                          event.target.value || null
                        )
                      }
                      placeholder="null"
                      options={selectOptions.priorityLevel}
                      labelHidden
                    />
                  ),
                  employmentType: (
                    <SelectField
                      value={jobOpportunity.employmentType}
                      onChange={(event) =>
                        updateJobOpportunity(
                          "employmentType",
                          event.target.value || null
                        )
                      }
                      placeholder="null"
                      options={selectOptions.employmentType}
                      labelHidden
                    />
                  ),
                  visibilityLevel: (
                    <SelectField
                      value={jobOpportunity.visibilityLevel}
                      onChange={(event) =>
                        updateJobOpportunity(
                          "visibilityLevel",
                          event.target.value
                        )
                      }
                      placeholder="null"
                      options={selectOptions.jobOppVisibilityLevel}
                      labelHidden
                    />
                  ),
                  creator: jobOpportunity.creator,
                  updater: jobOpportunity.updater,
                  jobLength: jobOpportunity.jobLength,
                  jobLengthInWeeks: jobOpportunity.jobLengthInWeeks,
                  startDate: jobOpportunity.startDate,
                  organization: jobOpportunity.organization,
                  status: (
                    <SelectField
                      value={jobOpportunity.status}
                      onChange={(event) =>
                        updateJobOpportunity("status", event.target.value)
                      }
                      placeholder="null"
                      options={selectOptions.status}
                      labelHidden
                    />
                  ),
                  timeCommitment: (
                    <SelectField
                      value={jobOpportunity.timeCommitment}
                      onChange={(event) =>
                        updateJobOpportunity(
                          "timeCommitment",
                          event.target.value
                        )
                      }
                      placeholder="null"
                      options={selectOptions.timeCommitment}
                      labelHidden
                    />
                  ),
                  timeOverlap: jobOpportunity.timeOverlap,
                  regions: (
                    <StringArrayField
                      values={jobOpportunity.regions}
                      updateField={(newValue) =>
                        updateJobOpportunity("regions", newValue)
                      }
                    />
                  ),
                }}
                updateData={updateJobOpportunity}
              />
              <FieldsSection
                heading="References"
                entity="jobOpportunity"
                fields={{
                  "customer.username": (
                    <Link to={`/users/${jobOpportunity.customer?.username}`}>
                      <span className="border-b-2 border-blue-200">
                        {jobOpportunity.customer?.username}
                      </span>
                    </Link>
                  ),
                  jobRole: (
                    <Link to={`/job-roles/${jobOpportunity.jobTypeId}`}>
                      <span className="border-b-2 border-blue-200">
                        {jobOpportunity.jobType?.title}
                      </span>
                    </Link>
                  ),
                }}
                updateData={updateJobOpportunity}
              />

              <FieldsSection
                heading="Torc Owner"
                entity="jobOpportunity"
                fields={{
                  "torcOwner.id": jobOpportunity.torcOwner?.id,
                  "torcOwner.username": jobOpportunity.torcOwner?.username,
                }}
                updateData={handlePairedFieldsChange}
                pairedFieldsKey="torcOwner"
              />

              <FieldsSection
                heading="Customer Owner"
                entity="jobOpportunity"
                fields={{
                  "customerOwner.id": jobOpportunity.customerOwner?.id,
                  "customerOwner.username":
                    jobOpportunity.customerOwner?.username,
                }}
                updateData={handlePairedFieldsChange}
                pairedFieldsKey="customerOwner"
              />

              <FieldsSection
                heading="Positions"
                entity="jobOpportunity"
                fields={{
                  requiredPositions: jobOpportunity.requiredPositions ?? 1,
                  openPositions: <span>{jobOpportunity.openPositions}</span>,
                }}
                updateData={updateJobOpportunity}
              />
              <FieldsSection
                entity="jobOpportunity"
                heading="Details"
                fields={{
                  overview: (
                    <TextAreaField
                      value={jobOpportunity.overview || ""}
                      onChange={(event) =>
                        updateJobOpportunity("overview", event.target.value)
                      }
                      resize="vertical"
                      labelHidden={true}
                    />
                  ),
                  requirements: (
                    <TextAreaField
                      value={jobOpportunity.requirements || ""}
                      onChange={(event) =>
                        updateJobOpportunity("requirements", event.target.value)
                      }
                      resize="vertical"
                      labelHidden={true}
                    />
                  ),
                  responsibilities: (
                    <TextAreaField
                      value={jobOpportunity.responsibilities || ""}
                      onChange={(event) =>
                        updateJobOpportunity(
                          "responsibilities",
                          event.target.value
                        )
                      }
                      resize="vertical"
                      labelHidden={true}
                    />
                  ),
                  shortDescription: (
                    <ShortDescription
                      updateJobOpportunity={updateJobOpportunity}
                      jobOpportunity={jobOpportunity}
                    />
                  ),
                }}
                updateData={updateJobOpportunity}
              />
              <Skills
                title="Required Skills"
                skills={jobOpportunity.skills || []}
                updateSkills={updateJobOpportunity}
              />

              <Skills
                title="Optional Skills"
                skills={jobOpportunity.optionalSkills || []}
                updateSkills={updateJobOpportunity}
                updateFieldKey="optionalSkills"
              />

              <Compensation
                handlePairedFieldsChange={handlePairedFieldsChange}
                jobOpportunity={jobOpportunity}
              />

              <FieldsSection
                heading="Timezone"
                entity="jobOpportunity"
                fields={{
                  "timezone.label": jobOpportunity.timezone?.label,
                  "timezone.value": jobOpportunity.timezone?.value,
                }}
                updateData={handlePairedFieldsChange}
                pairedFieldsKey="timezone"
              />
              <Metadata
                metadata={jobOpportunity.metadata}
                updateData={updateJobOpportunity}
                newKeyOptions={
                  JSON.parse(
                    process.env.REACT_APP_PREDEFINED_METADATA_KEYS ?? "{}"
                  ).JobOpportunity
                }
              />
            </TabItem>
            <TabItem title="Notes">
              <NoteList
                loadNotes={() => listJobOpportunityNotes(jobOpportunity.id)}
                createNotePayload={{ jobOpportunityId: jobOpportunity.id }}
              />
            </TabItem>
            <TabItem title="Matches">
              <div className="form-tab-table">
                <MatchList
                  matches={jobOpportunity.matches.items}
                  uniqueColumn="Username"
                />
              </div>
            </TabItem>
          </Tabs>
        </div>
      </div>
    </Card>
  );
};

export default JobOpportunityFields;
