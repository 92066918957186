import { API, graphqlOperation } from "aws-amplify";

import {
  getUserByUsernameQuery,
  getUserByIdentityUsername,
} from "./graphql/queries";

const getUserByUsername = async (username) => {
  let response = await API.graphql(
    graphqlOperation(getUserByUsernameQuery, { username })
  );

  if (!response.data.fields || response.data.fields.items?.length === 0) {
    response = await API.graphql(
      graphqlOperation(getUserByIdentityUsername, {
        identity_username: username,
      })
    );
  }
  return response;
};

export default getUserByUsername;
