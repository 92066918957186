import { API, graphqlOperation } from "aws-amplify";

import { deleteJobOpportunityMutation } from "./graphql/mutations";

const deleteJobOpportunity = (id) =>
  API.graphql(
    graphqlOperation(deleteJobOpportunityMutation, {
      input: { id },
    })
  );

export default deleteJobOpportunity;
