import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextAreaField,
  CheckboxField,
  Button,
} from "@aws-amplify/ui-react";
import { VscChromeClose } from "react-icons/vsc";
import ReactMarkdown from "react-markdown";

import createNote from "../../api/createNote";
import deleteNote from "../../api/deleteNote";
import { AlertContext } from "../../context/Alert";
import Loader from "../Loader";
import TextToCopy from "../TextToCopy";
import transformDate from "../../helpers/transformDate";

const initialNewNote = { content: "", isPublic: false };

const NoteList = ({ loadNotes, createNotePayload }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState(initialNewNote);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { showGraphQLError, showAlert } = useContext(AlertContext);

  const getNotes = async () => {
    try {
      setIsLoading(true);

      const response = await loadNotes();

      setNotes(response.data.listNotes.items);
      setIsLoaded(true);
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    try {
      setIsLoading(true);

      await createNote({ ...createNotePayload, ...newNote });

      setTimeout(getNotes, 1000);
      showAlert("Note is successfully saved", "success");
      setNewNote(initialNewNote);
    } catch (error) {
      showGraphQLError(error.errors);
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);

      await deleteNote(id);

      setTimeout(getNotes, 1000);
      showAlert("Note is successfully deleted", "success");
    } catch (error) {
      showGraphQLError(error.errors);
      setIsLoading(false);
    }
  };

  const sortedNotes = [...notes].sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  return (
    <>
      {isLoading && <Loader />}
      {isLoaded &&
        (sortedNotes.length ? (
          <div className="form-tab-table">
            <Table highlightOnHover={true} variation="striped">
              <TableHead>
                <TableRow>
                  {[
                    "ID",
                    "Customer Visible",
                    "Creator",
                    "Date Created",
                    "Content",
                    "Remove",
                  ].map((title) => (
                    <TableCell key={title} as="th">
                      <p
                        className={`whitespace-nowrap ${
                          title === "Content" ? "w-56" : ""
                        }`}
                      >
                        {title}
                      </p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedNotes.map(
                  ({ id, isPublic, creator, createdAt, content }) => (
                    <TableRow key={id}>
                      <TableCell>
                        <TextToCopy text={id} />
                      </TableCell>
                      <TableCell>{String(isPublic)}</TableCell>
                      <TableCell>
                        <Link to={`/users/${creator}`}>{creator}</Link>
                      </TableCell>
                      <TableCell>{transformDate(createdAt)}</TableCell>
                      <TableCell>
                        <ReactMarkdown children={content.slice(0, 255)} />
                        {content.length > 255 && <span>...</span>}
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => handleDelete(id)}>
                          <VscChromeClose />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          <p className="mt-10 font-semibold highlighted-error">No Notes</p>
        ))}
      <div className="flex gap-x-4 mt-10 p-0.5">
        <div className="grow">
          <TextAreaField
            value={newNote.content}
            onChange={(event) =>
              setNewNote((stateValue) => ({
                ...stateValue,
                content: event.target.value,
              }))
            }
            placeholder="New Note content"
            resize="vertical"
            labelHidden={true}
          />
        </div>
        <div className="flex flex-col justify-between">
          <CheckboxField
            label="Customer Visible"
            checked={newNote.isPublic}
            onChange={(event) =>
              setNewNote((stateValue) => ({
                ...stateValue,
                isPublic: event.target.checked,
              }))
            }
          />
          <Button onClick={handleSave} disabled={!newNote.content}>
            Save new Note
          </Button>
        </div>
      </div>
    </>
  );
};

export default NoteList;
