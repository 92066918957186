import { API, graphqlOperation } from "aws-amplify";

import { deleteMatchMutation } from "./graphql/mutations";

const deleteMatch = ({ applicationId, jobOpportunityId }) =>
  API.graphql(
    graphqlOperation(deleteMatchMutation, {
      input: {
        applicationId,
        jobOpportunityId,
      },
    })
  );

export default deleteMatch;
