import React, { useState } from "react";
import { Button, SelectField, TextField } from "@aws-amplify/ui-react";
import { VscClose } from "react-icons/vsc";

const StringArrayField = ({ values, options, updateField }) => {
  const [newValue, setNewValue] = useState("");

  const arrayOfValues = values ?? [];

  const handleChange = (event) => {
    setNewValue(event.target.value);
  };

  const handleAdd = () => {
    updateField([...arrayOfValues, newValue]);

    setNewValue("");
  };

  const handleDelete = (deletedValue) => {
    updateField(arrayOfValues.filter((value) => value !== deletedValue));
  };

  const availableOptions = options?.filter(
    (option) => !arrayOfValues.includes(option)
  );

  return (
    <>
      {arrayOfValues.length ? (
        <div className="flex flex-wrap gap-1 mb-2">
          {arrayOfValues.map((value) => (
            <div
              key={value}
              className="flex items-center gap-x-1 px-2 font-semibold bg-blue-100 rounded-md border"
            >
              {value}
              <button type="button" onClick={() => handleDelete(value)}>
                <VscClose />
              </button>
            </div>
          ))}
        </div>
      ) : null}
      <div className="flex gap-x-4 items-end [&>div:first-child]:grow">
        {options ? (
          <SelectField
            value={newValue}
            onChange={handleChange}
            placeholder="null"
            options={availableOptions}
            labelHidden
          />
        ) : (
          <TextField value={newValue} onChange={handleChange} labelHidden />
        )}
        <Button onClick={handleAdd} isDisabled={!newValue}>
          Add
        </Button>
      </div>
    </>
  );
};

export default StringArrayField;
