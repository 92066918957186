import { useParams } from "react-router-dom";

import getJobRole from "../../api/getJobRole";
import mutateJobRole from "../../api/mutateJobRole";
import useEditing from "../../hooks/useEditing";
import ActionButtons from "../ActionButtons";
import JobRoleFiels from "../JobRoleFields";
import Loader from "../Loader";

const JobRoleEdit = () => {
  const { jobRoleId } = useParams();

  const {
    isLoading,
    isNotFound,
    data: jobRole,
    updateData: updateJobRole,
    handlePairedFieldsChange,
    handleReset,
    handleSubmit,
  } = useEditing(jobRoleId, getJobRole, mutateJobRole, "jobRole");

  const isLoaded = !!Object.keys(jobRole).length;

  return (
    <>
      {isLoading && <Loader />}
      <main className="main">
        <div className="w-max ml-auto mb-4">
          {isLoaded && jobRoleId && (
            <ActionButtons
              handleReset={handleReset}
              handleSave={handleSubmit}
            />
          )}
        </div>
        {isNotFound && jobRoleId && (
          <p className="mt-10 font-semibold highlighted-error">
            No Application is found
          </p>
        )}
        {isLoaded && (
          <JobRoleFiels
            isLoading={isLoading}
            jobRole={jobRole}
            updateJobRole={updateJobRole}
            handlePairedFieldsChange={handlePairedFieldsChange}
          />
        )}
      </main>
    </>
  );
};

export default JobRoleEdit;
