import { useState } from "react";
import { VscInfo } from "react-icons/vsc";

const Tip = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <button
      className="relative z-10"
      onMouseEnter={() => setIsOpened(true)}
      onMouseLeave={() => setIsOpened(false)}
    >
      <VscInfo size={24} />
      {isOpened && (
        <div className="absolute bottom-[32px] left-1/2 w-max max-w-[400px] px-4 py-2 text-white bg-opacity-80 bg-black rounded-md -translate-x-1/2">
          {children}
        </div>
      )}
    </button>
  );
};

export default Tip;
