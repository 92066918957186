const setPropertyValue = (path, object, value) => {
  if (!path.includes(".")) {
    return (object[path] = value);
  }

  const keys = path.split(".");
  const propertyToChange = keys[keys.length - 1];

  keys.pop();
  keys.reduce((object, key) => {
    if (!object[key]) {
      object[key] = {};
    }

    return object[key];
  }, object)[propertyToChange] = value;
};

export default setPropertyValue;
