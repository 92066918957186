import { CheckboxField } from "@aws-amplify/ui-react";

import UserCognitoGroups from "../UserCognitoGroups";
import FieldsSection from "../FieldsSection";
import UserDelete from "../UserDelete";
import Metadata from "../Metadata";

const SystemFields = ({ user, updateUser }) => {
  return (
    <>
      <UserCognitoGroups username={user.username || user.identity_username} />
      <FieldsSection
        heading="Agreement"
        entity="user"
        fields={{
          agreedToMarketing: (
            <CheckboxField
              checked={user.agreedToMarketing || false}
              onChange={(event) =>
                updateUser("agreedToMarketing", event.target.checked)
              }
              labelHidden
            />
          ),
          agreedToTerms: (
            <CheckboxField
              checked={user.agreedToTerms || false}
              onChange={(event) =>
                updateUser("agreedToTerms", event.target.checked)
              }
              labelHidden
            />
          ),
        }}
        updateData={updateUser}
      />
      <FieldsSection
        heading="Referral program"
        entity="user"
        fields={{
          referralCode: user.referralCode,
          referralCount: user.referralCount,
          referrerCode: user.referrerCode,
        }}
        updateData={updateUser}
      />
      <Metadata
        metadata={user.metadata}
        updateData={updateUser}
        newKeyOptions={
          JSON.parse(process.env.REACT_APP_PREDEFINED_METADATA_KEYS ?? "{}")
            .User
        }
      />
      <FieldsSection
        heading="Technical"
        entity="user"
        fields={{
          nextAvailableDate: user.nextAvailableDate,
          lastActivityDate: user.lastActivityDate,
          githubAccessToken: user.githubAccessToken,
          githubRefreshToken: user.githubRefreshToken,
          visibility: user.visibility,
        }}
        updateData={updateUser}
      />
      <UserDelete
        id={user.id}
        username={user.username || user.identity_username}
        applications={user.applications.items}
        jobOpportunities={user.jobOpportunities.items}
      />
    </>
  );
};

export default SystemFields;
