import { Link } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";
import { VscArrowLeft } from "react-icons/vsc";

const BackButton = ({ path }) => (
  <Button as={Link} to={path} size="small">
    <VscArrowLeft />
    <span className="ml-1">Back to list</span>
  </Button>
);

export default BackButton;
