import { useState, useEffect, useContext } from "react";
import {
  TextField,
  Loader,
  Button,
  CheckboxField,
} from "@aws-amplify/ui-react";

import { AlertContext } from "../../context/Alert";
import getAllBadges from "../../api/getAllBadges";
import createBadge from "../../api/createBadge";

const BadgeAdding = ({
  badges,
  newBadge,
  setNewBadge,
  userId,
  refreshBadges,
}) => {
  const [query, setQuery] = useState("");
  const [allBadges, setAllBadges] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWithNotification, setIsWithNotification] = useState(true);
  const { showGraphQLError, showAlert } = useContext(AlertContext);

  const fetchAllBadges = async () => {
    try {
      const response = await getAllBadges();

      setAllBadges(response.data.getBadgesFromIssuer);
    } catch (error) {
      console.error(error.errors);
    }
  };

  useEffect(() => {
    fetchAllBadges();
  }, []);

  const handleAdd = (newBadge) => {
    setQuery("");
    setNewBadge(newBadge);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      const response = await createBadge(
        newBadge.entityId,
        userId,
        isWithNotification
      );

      showAlert(response.data.createBadge.message, "success");

      setTimeout(refreshBadges, 3000);
    } catch (error) {
      setNewBadge(null);
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
      setNewBadge(null);
    }
  };

  const filteredBadges = allBadges
    .filter((badge) => badges.every(({ name }) => name !== badge.name))
    .filter((badge) => badge.name.toLowerCase().includes(query.toLowerCase()));

  return (
    <div className="flex gap-x-4 items-end">
      <div className="grow relative">
        <TextField
          placeholder="Find new"
          value={query}
          onChange={handleQueryChange}
          onFocus={() => setIsDropdownOpen(true)}
          onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)}
          innerEndComponent={
            <span
              className={`mr-5 mt-4 arrow ${isDropdownOpen ? "up" : "down"}`}
            />
          }
        />
        {isDropdownOpen && (
          <div className="absolute top-full w-full max-h-[300px] mt-1 rounded-sm bg-white shadow flex flex-col gap-2 z-20 overflow-auto">
            {filteredBadges.length ? (
              filteredBadges.map((badge) => (
                <p
                  key={badge.entityId}
                  className="hover:bg-gray-200 px-4 py-1 cursor-pointer"
                  onClick={() => handleAdd(badge)}
                >
                  {badge.name}
                </p>
              ))
            ) : (
              <div className="p-4">
                <Loader />
              </div>
            )}
          </div>
        )}
      </div>
      <CheckboxField
        checked={isWithNotification}
        onChange={(event) => setIsWithNotification(event.target.checked)}
        label="Send&nbsp;email&nbsp;notification"
        size="small"
      />
      <Button
        onClick={handleSave}
        disabled={!newBadge}
        size="small"
        isLoading={isLoading}
        loadingText="Saving"
        style={{ width: "170px" }}
      >
        Save newly added
      </Button>
    </div>
  );
};

export default BadgeAdding;
