import { Card, CheckboxField, TextAreaField } from "@aws-amplify/ui-react";

import createJobRole from "../../api/createJobRole";
import useEditing from "../../hooks/useEditing";
import ActionButtons from "../ActionButtons";
import Loader from "../Loader";
import FieldsSection from "../FieldsSection";
import Skills from "../Skills";

const JobRoleCreate = () => {
  const {
    isLoading,
    data: jobRole,
    updateData: updateJobRole,
    handleReset,
    handleSubmit,
  } = useEditing("create", undefined, createJobRole, "jobRole");

  return (
    <>
      {isLoading && <Loader />}
      <main className="main">
        <div className="flex justify-end items-center mb-4">
          <ActionButtons handleReset={handleReset} handleSave={handleSubmit} />
        </div>
        <Card variation="elevated">
          <div className="form">
            <section className="basic-info">
              <h2 className="w-max mt-4 px-2 text-xl font-bold bg-blue-100">
                New Job Role Creation
              </h2>
              <h2 className="w-max mt-4 mb-8 px-2 text-xl font-bold bg-blue-100">
                {jobRole.title}
              </h2>
            </section>
            <div className="grow">
              <FieldsSection
                className="mt-2"
                entity="jobRole"
                fields={{
                  title: jobRole.title,
                  creator: jobRole.creator,
                  updater: jobRole.updater,
                  isActive: (
                    <CheckboxField
                      checked={jobRole.isActive || false}
                      onChange={(event) =>
                        updateJobRole("isActive", event.target.checked)
                      }
                      labelHidden
                    />
                  ),
                }}
                updateData={updateJobRole}
              />
              <FieldsSection
                entity="jobRole"
                heading="Cooperation requirements"
                fields={{
                  commitment: jobRole.commitment,
                  lowEndLength: jobRole.lowEndLength,
                  highEndLength: jobRole.highEndLength,
                }}
                updateData={updateJobRole}
              />
              <Skills
                skills={jobRole.skills || []}
                updateSkills={updateJobRole}
              />
              <FieldsSection
                entity="jobRole"
                heading="Details"
                fields={{
                  overview: (
                    <TextAreaField
                      value={jobRole.overview || ""}
                      onChange={(event) =>
                        updateJobRole("overview", event.target.value)
                      }
                      resize="vertical"
                      labelHidden={true}
                    />
                  ),
                  requirements: (
                    <TextAreaField
                      value={jobRole.requirements || ""}
                      onChange={(event) =>
                        updateJobRole("requirements", event.target.value)
                      }
                      resize="vertical"
                      labelHidden={true}
                    />
                  ),
                  responsibilities: (
                    <TextAreaField
                      value={jobRole.responsibilities || ""}
                      onChange={(event) =>
                        updateJobRole("responsibilities", event.target.value)
                      }
                      resize="vertical"
                      labelHidden={true}
                    />
                  ),
                }}
                updateData={updateJobRole}
              />
            </div>
          </div>
        </Card>
      </main>
    </>
  );
};

export default JobRoleCreate;
