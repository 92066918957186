import Careers from "../Experience/Careers";
import ProjectsCaseStudies from "../Experience/ProjectsCaseStudies";
import Education from "../Experience/Education";

const ExperienceWrapper = ({ user, updateData, saveUser }) => {
  return (
    <div className="mt-4">
      <p className="text-lg font-bold">Experience</p>
      <div className="p-2">
        <Careers
          careers={user.careers || []}
          updateData={updateData}
          saveUser={saveUser}
        />
        <ProjectsCaseStudies
          projectsCaseStudies={user.projectsCaseStudies || []}
          updateData={updateData}
          saveUser={saveUser}
        />
        <Education
          educationRecords={user.educationRecords || []}
          updateData={updateData}
          saveUser={saveUser}
        />
      </div>
    </div>
  );
};

export default ExperienceWrapper;
