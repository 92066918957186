const mapAdminDomainToPlatformDomain = {
  localhost: "https://platform-dev.torc.dev/",
  "admin-dev.torc.dev": "https://platform-dev.torc.dev/",
  "admin-staging.torc.dev": "https://platform-staging.torc.dev/",
  "admin.torc.dev": "https://platform.torc.dev/",
};

const getPlatformSiteDomain = () =>
  mapAdminDomainToPlatformDomain[window.location.hostname] ||
  "https://platform.torc.dev/";

export const isProduction = () => {
  return window.location.hostname === "admin.torc.dev";
};

export default getPlatformSiteDomain;
