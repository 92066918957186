export const updateUserMutation = `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
    }
  }
`;

export const updateJobRoleMutation = `
  mutation UpdateJobType($input: UpdateJobTypeInput!) {
    updateJobType(input: $input) {
      id
    }
  }
`;

export const updateApplicationMutation = `
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      id
    }
  }
`;

export const updateJobOpportunityMutation = `
  mutation UpdateJobOpportunity($input: UpdateJobOpportunityInput!) {
    updateJobOpportunity(input: $input) {
      id
    }
  }
`;

export const createBadgeMutation = `
  mutation CreateBadge($input: CreateBadgeInput!) {
    createBadge(input: $input) {
      message
    }
  }
`;

export const deleteUserMutation = `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;

export const deleteApplicationMutation = `
  mutation DeleteApplication($input: DeleteApplicationInput!) {
    deleteApplication(input: $input) {
      id
    }
  }
`;

export const deleteJobOpportunityMutation = `
  mutation DeleteJobOpportunity($input: DeleteJobOpportunityInput!) {
    deleteJobOpportunity(input: $input) {
      id
    }
  }
`;

export const createApplicationMutation = `
  mutation CreateApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      id
    }
  }
`;

export const updateMatchMutation = `
  mutation UpdateMatch($input: UpdateMatchInput!) {
    updateMatch(input: $input) {
      applicationId
      jobOpportunityId
    }
  }
`;

export const deleteMatchMutation = `
  mutation DeleteMatch($input: DeleteMatchInput!) {
    deleteMatch(input: $input) {
      applicationId
      jobOpportunityId
    }
  }
`;

export const createJobRoleMutation = `
  mutation CreareJobType($input: CreateJobTypeInput!) {
    fields: createJobType(input: $input) {
      id
    }
  }
`;

export const createNoteMutation = `
  mutation CreateNote ($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
    }
  }
`;

export const deleteNoteMutation = `
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
    }
  }
`;

export const createAssessmentMutation = `
  mutation CreateAssessment ($input: CreateAssessmentInput!) {
    createAssessment(input: $input) {
      message
    }
  }
`;

export const manageUserCognitoGroup = /* GraphQL */ `
  mutation ManageUserCognitoGroup($input: ManageCognitoGroupInput!) {
    manageUserCognitoGroup(input: $input) {
      message
    }
  }
`;
