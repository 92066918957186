import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@aws-amplify/ui-react";

import TextToCopy from "../TextToCopy";

const JobOpportunityList = ({ jobOpportunities, isCustomerHidden = false }) =>
  jobOpportunities.length ? (
    <Table highlightOnHover={true} variation="striped">
      <TableHead>
        <TableRow>
          {[
            "ID",
            "Title",
            "Status",
            isCustomerHidden ? "Job Role" : "Username",
          ].map((title) => (
            <TableCell key={title} as="th">
              <span className="whitespace-nowrap">{title}</span>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {jobOpportunities.map(
          ({ id, title, status, customer, jobType: jobRole }) => (
            <TableRow key={id}>
              <TableCell>
                <TextToCopy text={id} />
              </TableCell>
              <TableCell>
                <Link
                  to={`/job-opportunities/${id}`}
                  className="hover:underline"
                >
                  {title}
                </Link>
              </TableCell>
              <TableCell>{status}</TableCell>
              <TableCell>
                {isCustomerHidden ? (
                  <Link
                    to={`/job-roles/${jobRole.id}`}
                    className="hover:underline"
                  >
                    {jobRole.title}
                  </Link>
                ) : (
                  customer?.username && (
                    <Link
                      to={`/users/${customer.username}`}
                      className="hover:underline"
                    >
                      {customer.username}
                    </Link>
                  )
                )}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  ) : (
    <p className="font-semibold highlighted-error">No Job Opportunities</p>
  );

export default JobOpportunityList;
