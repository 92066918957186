import { API, graphqlOperation } from "aws-amplify";

import { createAssessmentMutation } from "./graphql/mutations";

const createAssessment = (payload) =>
  API.graphql(
    graphqlOperation(createAssessmentMutation, {
      input: payload,
    })
  );

export default createAssessment;
