import { useState, useEffect } from "react";
import { Autocomplete, TextField, Button } from "@aws-amplify/ui-react";
import { VscClose } from "react-icons/vsc";
import { v4 as uuidv4 } from "uuid";

const Metadata = ({ metadata, updateData, newKeyOptions = [] }) => {
  const [entries, setEntries] = useState([]);
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    const metadataObject = JSON.parse(metadata ?? "{}");

    setEntries(
      Object.keys(metadataObject).map((key) => ({
        key,
        value: metadataObject[key],
        uuid: uuidv4(),
      }))
    );
  }, [metadata]);

  const updateDataWrapper = (updatedMetadata) => {
    updateData(
      "metadata",
      JSON.stringify(
        updatedMetadata.reduce(
          (acc, { key, value }) => ({ ...acc, [key]: value }),
          {}
        )
      )
    );
  };

  const handleEntryChange = (value, id, keyOrValue) => {
    setEntries((currentEntries) =>
      currentEntries.map((entry) => {
        if (entry.uuid === id) {
          return {
            ...entry,
            [keyOrValue]: value,
          };
        }

        return entry;
      })
    );
  };

  const handlePropertyDelete = (id) => {
    updateDataWrapper(entries.filter(({ uuid }) => id !== uuid));
  };

  const handlePropertyAdd = () => {
    updateDataWrapper([...entries, { key: newKey, value: newValue }]);
    setNewKey("");
    setNewValue("");
  };

  return (
    <section className="mt-10">
      <h2 className="font-bold">Metadata</h2>
      <div className="dashed-section">
        {entries.length ? (
          <>
            <div className="flex gap-x-4 mr-8">
              <p className="basis-1/2 font-semibold">keys</p>
              <p className="basis-1/2 font-semibold">values</p>
            </div>
            {entries.map(({ uuid, key, value }) => (
              <div
                key={uuid}
                className="flex items-center gap-x-4 mt-2 [&>div]:grow"
              >
                <TextField
                  value={key}
                  onChange={(event) =>
                    handleEntryChange(event.target.value, uuid, "key")
                  }
                  onBlur={() => updateDataWrapper(entries)}
                  labelHidden
                />
                <TextField
                  value={value}
                  onChange={(event) =>
                    handleEntryChange(event.target.value, uuid, "value")
                  }
                  onBlur={() => updateDataWrapper(entries)}
                  labelHidden
                />
                <button
                  type="button"
                  onClick={() => handlePropertyDelete(uuid)}
                >
                  <VscClose />
                </button>
              </div>
            ))}
          </>
        ) : (
          <p>No metadata</p>
        )}
        <div className="flex gap-x-4 mt-4 [&>div]:grow">
          <Autocomplete
            options={newKeyOptions.map((option) => ({
              id: option,
              label: option,
            }))}
            placeholder="Choose or enter your own"
            value={newKey}
            onChange={(event) => setNewKey(event.target.value)}
            onSelect={({ label }) => setNewKey(label)}
            onClear={() => setNewKey("")}
            labelHidden
          />
          <TextField
            value={newValue}
            onChange={(event) => setNewValue(event.target.value)}
            labelHidden
          />
          <Button onClick={handlePropertyAdd} disabled={!newKey || !newValue}>
            <span className="whitespace-nowrap">Add new</span>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Metadata;
