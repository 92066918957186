import { API, graphqlOperation } from "aws-amplify";

import { generateJobShortDescriptionQuery } from "./graphql/queries";

const generateJobShortDescription = (jobDetails) =>
  API.graphql(
    graphqlOperation(generateJobShortDescriptionQuery, { jobDetails })
  );

export default generateJobShortDescription;
