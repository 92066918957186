import {
  Card,
  Placeholder,
  CheckboxField,
  TextAreaField,
} from "@aws-amplify/ui-react";

import FieldsSection from "../FieldsSection";
import Skills from "../Skills";
import PlatformRedirectButton from "../PlatformRedirectButton";
import Metadata from "../Metadata";

const JobRoleFields = ({ isLoading, jobRole, updateJobRole }) => {
  return (
    <Card variation="elevated">
      <div className="form">
        <section className="basic-info">
          <h2 className="w-max mt-4 px-2 text-xl font-bold bg-blue-100">
            Job Role:
          </h2>
          <h2 className="w-max mt-4 mb-8 px-2 text-xl font-bold bg-blue-100">
            {jobRole.title}
          </h2>
          {["id", "createdAt", "updatedAt"].map((field) => (
            <div className="mt-2" key={field}>
              <p className="font-semibold">{field}</p>
              <div className="h-6">
                {isLoading ? <Placeholder className="mt-2" /> : jobRole[field]}
              </div>
            </div>
          ))}
          <PlatformRedirectButton
            entity="Job Role"
            path={`#/jobs/roles/${jobRole.id}`}
          />
        </section>
        <div className="grow">
          <FieldsSection
            className="mt-2"
            entity="jobRole"
            fields={{
              title: jobRole.title,
              creator: jobRole.creator,
              updater: jobRole.updater,
              isActive: (
                <CheckboxField
                  checked={jobRole.isActive || false}
                  onChange={(event) =>
                    updateJobRole("isActive", event.target.checked)
                  }
                  labelHidden
                />
              ),
            }}
            updateData={updateJobRole}
          />
          <FieldsSection
            entity="jobRole"
            heading="Cooperation requirements"
            fields={{
              commitment: jobRole.commitment,
              lowEndLength: jobRole.lowEndLength,
              highEndLength: jobRole.highEndLength,
            }}
            updateData={updateJobRole}
          />
          <Skills skills={jobRole.skills || []} updateSkills={updateJobRole} />
          <FieldsSection
            entity="jobRole"
            heading="Details"
            fields={{
              overview: (
                <TextAreaField
                  value={jobRole.overview || ""}
                  onChange={(event) =>
                    updateJobRole("overview", event.target.value)
                  }
                  resize="vertical"
                  labelHidden={true}
                />
              ),
              requirements: (
                <TextAreaField
                  value={jobRole.requirements || ""}
                  onChange={(event) =>
                    updateJobRole("requirements", event.target.value)
                  }
                  rows={jobRole.requirements?.split("\n").length || 3}
                  resize="vertical"
                  labelHidden={true}
                />
              ),
              responsibilities: (
                <TextAreaField
                  value={jobRole.responsibilities || ""}
                  onChange={(event) =>
                    updateJobRole("responsibilities", event.target.value)
                  }
                  rows={jobRole.responsibilities?.split("\n").length || 3}
                  resize="vertical"
                  labelHidden={true}
                />
              ),
            }}
            updateData={updateJobRole}
          />
          <Metadata
            metadata={jobRole.metadata}
            updateData={updateJobRole}
            newKeyOptions={
              JSON.parse(process.env.REACT_APP_PREDEFINED_METADATA_KEYS ?? "{}")
                .JobRole
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default JobRoleFields;
