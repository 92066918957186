import SearchFields from "../SearchFields";

const UserSearch = () => (
  <main className="main">
    <SearchFields
      placeholder="Search by username, email or ID"
      entity="users"
    />
  </main>
);

export default UserSearch;
