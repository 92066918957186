import { useState } from "react";
import {
  SelectField,
  TextField,
  Button,
  Expander,
  ExpanderItem,
} from "@aws-amplify/ui-react";

import { selectOptions } from "../../constants";

const initialNewLinkState = {
  name: "",
  value: "",
  description: "",
  type: "GENERAL",
  visibility: "PRIVATE",
};

const OtherLinks = ({ links, updateData }) => {
  const [newLink, setNewLink] = useState(initialNewLinkState);

  const handleChange = (field, value) => {
    setNewLink((link) => ({ ...link, [field]: value }));
  };

  const handleSave = () => {
    if (links?.some(({ name }) => name === newLink.name)) {
      updateData(
        "otherLinks",
        links.map((link) => (link.name === newLink.name ? newLink : link))
      );
    } else {
      updateData("otherLinks", [...(links || []), newLink]);
    }

    setNewLink(initialNewLinkState);
  };

  const removeLink = (name) => {
    updateData(
      "otherLinks",
      links.filter((link) => link.name !== name)
    );
  };

  return (
    <section className="mt-10">
      <h2 className="font-bold">Other links</h2>
      <div className="dashed-section">
        <div className="mb-6">
          {links?.length
            ? links.map(({ name, value, description, type, visibility }) => (
                <div key={name} className="relative my-4">
                  <div className="flex flex-nowrap items-center">
                    <p className="basis-1/3 shrink-0">{name}</p>
                    <a
                      href={value}
                      className="grow pr-6 break-all"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {value}
                    </a>
                  </div>
                  <p className="pr-6 text-sm text-gray-400">{description}</p>
                  <p className="text-sm text-gray-400">
                    {type} - {visibility}
                  </p>
                  <button
                    className="absolute top-0.5 right-0"
                    onClick={() => removeLink(name)}
                  >
                    &#x2717;
                  </button>
                </div>
              ))
            : "No links"}
        </div>
        <Expander
          type="single"
          isCollapsible
          style={{ boxShadow: "none", border: "1px solid grey" }}
        >
          <ExpanderItem title="Add new link" value="newOtherLink">
            <div className="flex gap-x-4 mb-4">
              <div className="grow">
                <TextField
                  value={newLink.name}
                  onChange={(event) => handleChange("name", event.target.value)}
                  placeholder="name"
                  labelHidden
                />
              </div>
              <div className="grow">
                <TextField
                  value={newLink.value}
                  onChange={(event) =>
                    handleChange("value", event.target.value)
                  }
                  placeholder="value"
                  labelHidden
                />
              </div>
            </div>
            <TextField
              value={newLink.description}
              onChange={(event) =>
                handleChange("description", event.target.value)
              }
              placeholder="description (optional)"
              labelHidden
            />
            <div className="flex justify-between gap-x-4 mt-4">
              <div className="flex items-end gap-x-4">
                <SelectField
                  value={newLink.type}
                  onChange={(event) => handleChange("type", event.target.value)}
                  options={selectOptions.otherLinkType}
                  labelHidden
                />
                <SelectField
                  value={newLink.visibility}
                  onChange={(event) =>
                    handleChange("visibility", event.target.value)
                  }
                  options={selectOptions.otherLinkVisibility}
                  labelHidden
                />
              </div>
              <Button
                onClick={handleSave}
                isDisabled={!newLink.name || !newLink.value}
                style={{ whiteSpace: "nowrap" }}
              >
                Add or replace
              </Button>
            </div>
          </ExpanderItem>
        </Expander>
      </div>
    </section>
  );
};

export default OtherLinks;
