// @todo: implement

import { useEffect, useState } from "react";
import { Button, SelectField } from "@aws-amplify/ui-react";
import getCognitoGroupsForUser from "../../api/getCognitoGroupsForUser";
import getAllCognitoGroups from "../../api/listCognitoGroups";
import addRemoveUserCognitoGroup from "../../api/manageUserCognitoGroup";

const GROUP_ACTION_TYPES = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

function ExistingCognitoGroup({ group, onRemove }) {
  const [removing, setRemoving] = useState(false);

  const removeFromGroup = () => {
    setRemoving(true);
    onRemove(group);
  };

  return (
    <div className="group flex flex-nowrap items-center my-2" key={group}>
      <p className="basis-1/3">{group}</p>
      <div className="grow">
        <Button size="small" onClick={removeFromGroup} disabled={removing}>
          {removing ? "Removing..." : "Remove"}
        </Button>
      </div>
    </div>
  );
}

const UserCognitoGroups = ({ username }) => {
  const [loading, setLoading] = useState(true);
  const [cognitoGroups, setCognitoGroups] = useState([]);
  const [userCognitoGroups, setUserCognitoGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [addingToGroup, setAddingToGroup] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await getCognitoGroupsForUser(username);
      setUserCognitoGroups(res.data.getCognitoGroupsForUser.groups);
      setLoading(false);
    })();
  }, [username]);

  useEffect(() => {
    (async () => {
      const res = await getAllCognitoGroups();
      setCognitoGroups(res.data.listCognitoGroups.groups);
    })();
  }, []);

  const addUserToGroup = async () => {
    if (!selectedGroup) {
      return;
    }

    setAddingToGroup(true);
    await addRemoveUserCognitoGroup(
      username,
      selectedGroup,
      GROUP_ACTION_TYPES.ADD
    );
    setAddingToGroup(false);
    setUserCognitoGroups((prev) => [...prev, selectedGroup]);
    setSelectedGroup("");
  };

  const removeUserFromGroup = async (group) => {
    await addRemoveUserCognitoGroup(username, group, GROUP_ACTION_TYPES.REMOVE);
    setUserCognitoGroups((prev) => prev.filter((g) => g !== group));
  };

  let availableGroups = cognitoGroups.filter(
    (group) => !userCognitoGroups.includes(group)
  );

  return (
    <section className="mt-10">
      <h2 className="font-bold">
        <span className="bg-slate-200">{username}</span>
        's Cognito Groups
      </h2>
      <div className="dashed-section">
        {userCognitoGroups.map((group) => (
          <ExistingCognitoGroup
            group={group}
            key={group}
            onRemove={removeUserFromGroup}
          />
        ))}
        {loading && "Loading..."}
        <div className="flex flex-nowrap items-end mt-8 mb-2">
          <div className="grow">
            <SelectField
              placeholder="Choose new group"
              options={availableGroups}
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
            />
          </div>
          <div className="basis-1/3 text-right">
            <Button
              size="small"
              disabled={addingToGroup}
              onClick={addUserToGroup}
            >
              {addingToGroup ? "Adding..." : "Add to group"}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserCognitoGroups;
