import { API, graphqlOperation } from "aws-amplify";

import { deleteNoteMutation } from "./graphql/mutations";

const deleteNote = (id) =>
  API.graphql(
    graphqlOperation(deleteNoteMutation, {
      input: { id },
    })
  );

export default deleteNote;
