import React, { useState, useEffect } from "react";
import { Image, Transformation, Placeholder } from "cloudinary-react";

const ImageViewer = ({ objectKey, placeholder, ...props }) => {
  const [publicId, setPublicId] = useState(null);

  useEffect(() => {
    if (!objectKey) {
      return;
    }

    setPublicId(objectKey);
  }, [objectKey]);

  if (!objectKey) {
    return placeholder || null;
  }

  return (
    <Image
      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
      upload_preset={process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}
      publicId={publicId}
      secure={true}
    >
      <Transformation fetch_format="auto" quality="auto" {...props} />
      <Placeholder type="vectorize" />
    </Image>
  );
};

export default ImageViewer;
