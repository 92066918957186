import { API, graphqlOperation } from "aws-amplify";

import { createNoteMutation } from "./graphql/mutations";

const createNote = (payload) =>
  API.graphql(
    graphqlOperation(createNoteMutation, {
      input: payload,
    })
  );

export default createNote;
