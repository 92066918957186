import { useState, useContext } from "react";
import { TextField } from "@aws-amplify/ui-react";

import Modal from "../Modal";
import Loader from "../Loader";
import deleteUser from "../../api/deleteUser";
import deleteApplication from "../../api/deleteApplication";
import deleteJobOpportunity from "../../api/deleteJobOpportunity";
import { AlertContext } from "../../context/Alert";

const UserDelete = ({ username, id, applications, jobOpportunities }) => {
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
  const [isExplanationOpened, setIsExplanationOpened] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isInputError, setIsInputError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { showGraphQLError } = useContext(AlertContext);

  const handleInputChange = (event) => {
    setIsInputError(false);
    setInputValue(event.target.value);
  };

  const removeUser = async () => {
    try {
      await deleteUser(id);

      window.location.reload();
    } catch (error) {
      showGraphQLError(error.errors);
    }
  };

  const removeApplication = async (id = applications[0]?.id) => {
    if (!id) {
      return true;
    }

    try {
      const applicationIndex = applications.findIndex(
        (application) => application.id === id
      );

      await deleteApplication(id);

      return await removeApplication(
        applications[applicationIndex + 1]?.id || false
      );
    } catch (error) {
      showGraphQLError(error.errors);

      return false;
    }
  };

  const removeJobOpportunity = async (id = jobOpportunities[0]?.id) => {
    if (!id) {
      return true;
    }

    try {
      const jobOpportunityIndex = jobOpportunities.findIndex(
        (jobOpportunityIndex) => jobOpportunityIndex.id === id
      );

      await deleteJobOpportunity(id);

      return await removeJobOpportunity(
        jobOpportunities[jobOpportunityIndex + 1]?.id || false
      );
    } catch (error) {
      showGraphQLError(error.errors);

      return false;
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);

    const applicationsRemovalResult = await removeApplication();
    const jobOpportunitiesRemovalResult = await removeJobOpportunity();

    if (applicationsRemovalResult && jobOpportunitiesRemovalResult) {
      await removeUser();
    }

    setIsLoading(false);
  };

  const handleConfirmation = () => {
    if (username !== inputValue) {
      setIsInputError(true);

      return;
    }

    setIsExplanationOpened(true);
  };

  return (
    <>
      <button
        type="button"
        className="w-full mt-10 p-2 text-rose-700 border-2 border-rose-700 uppercase font-semibold"
        onClick={() => setIsConfirmationOpened(true)}
      >
        Delete this User
      </button>
      {isLoading && <Loader />}
      {isConfirmationOpened && (
        <Modal onClose={() => setIsConfirmationOpened(false)}>
          <h2 className="text-lg uppercase font-bold">Confirmation</h2>
          <p className="mt-4">
            All User's data together with his/her Applications / Job
            Opportunities will be deleted.
          </p>
          <p>
            Please enter username of the User who should be deleted in order to
            confirm.
          </p>
          <div className="flex mt-4 gap-x-4 items-start">
            <div className="grow">
              <TextField
                value={inputValue}
                onChange={handleInputChange}
                labelHidden
                hasError={isInputError}
                errorMessage="Wrong username"
              />
            </div>
            <button
              type="button"
              className="p-2 text-rose-700 border-2 border-rose-700 uppercase font-semibold"
              onClick={handleConfirmation}
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
      {isExplanationOpened && (
        <Modal onClose={() => setIsExplanationOpened(false)}>
          <h2 className="text-lg uppercase font-bold">Explanation</h2>
          <p className="mt-4">
            Currently specific parts of deletion process are not supported from
            admin-site.
          </p>
          <p>
            All Applications and Job Opportunities as well as user entity will
            be deleted from DynamoDB automatically.
          </p>
          <ul className="mt-4 list-disc font-semibold">
            Please make sure also to:
            <li className="ml-6 mt-2">delete user from Cognito;</li>
            <li className="ml-6">revoke badges for user;</li>
            <li className="ml-6">clear out badges from DynamoDB;</li>
            <li className="ml-6">clear out tokens for GitHub user.</li>
          </ul>
          <p className="mt-4">
            User ID: <span className="underline">{id}</span>
          </p>
          <button
            type="button"
            className="w-full mt-6 p-2 text-rose-700 border-2 border-rose-700 uppercase font-semibold"
            onClick={handleDelete}
          >
            Proceed with deletion
          </button>
        </Modal>
      )}
    </>
  );
};

export default UserDelete;
