import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@aws-amplify/ui-react";

import TextToCopy from "../TextToCopy";

const JobRoleList = ({ jobRoles }) => (
  <Table highlightOnHover={true} variation="striped">
    <TableHead>
      <TableRow>
        {["ID", "Title", "Is Active", "Application Count"].map((title) => (
          <TableCell key={title} as="th">
            <span className="whitespace-nowrap">{title}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {jobRoles.map(({ id, title, applicationCount, isActive }) => (
        <TableRow key={id}>
          <TableCell>
            <TextToCopy text={id} />
          </TableCell>
          <TableCell>
            <Link to={`/job-roles/${id}`} className="hover:underline">
              {title}
            </Link>
          </TableCell>
          <TableCell>{isActive ? "true" : "false"}</TableCell>
          <TableCell>{applicationCount}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default JobRoleList;
