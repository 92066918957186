// @todo: implement

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SelectField,
  Button,
  TextField,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react";

const CognitoGroups = () => {
  const navigate = useNavigate();
  // const [groupUsers, setGroupUsers] = useState([]);
  const [query, setQuery] = useState("");

  const handleSearch = (event) => {
    setQuery(event.target.value);
  };

  return (
    <main className="main">
      <div className="flex gap-x-4">
        <SelectField
          placeholder="All cognito groups"
          options={[
            "l cognito groups ll cognito groups ll cognito groups ll cognito groups ll cognito groups",
          ]}
          labelHidden={true}
        />
        <Button>Show users</Button>
      </div>

      {true && (
        <>
          <div className="my-4 flex justify-end">
            <TextField
              value={query}
              onChange={handleSearch}
              placeholder="Search user"
              labelHidden={true}
            />
          </div>
          <Table highlightOnHover={true} variation="striped">
            <TableHead>
              <TableRow>
                <TableCell as="th">username</TableCell>
                <TableCell as="th">preferred_username</TableCell>
                <TableCell as="th">email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow onClick={() => navigate("/users/bartolomeu?tab=3")}>
                <TableCell>Orange</TableCell>
                <TableCell>Nectarine</TableCell>
                <TableCell>Raspberry</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </main>
  );
};

export default CognitoGroups;
